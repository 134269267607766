import { Dialog, Transition } from "@headlessui/react";
import { motion } from "framer-motion";
import moment from "moment/moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "./Back";
import { useSchedulesState } from "./Home";
import UnitDeleteConfirm from "./UnitDeleteConfirm";
import client, { useInjectToken } from "./client";
import { useSession } from "@clerk/clerk-react";

/* This example requires Tailwind CSS v2.0+ */

const Root = Transition.Root;

function scrollTo(offset, callback) {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener("scroll", onScroll);
      callback();
    }
  };

  window.addEventListener("scroll", onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: "smooth",
  });
}

export function Units({ show, setShow, cb }) {
  const homeState = useSchedulesState();
  const navigate = useNavigate();

  return (
    <Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setShow(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      Units
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Click on a unit to quickly navigate to it
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center relative">
                  <ul
                    role="list"
                    className="grid grid-cols-2 gap-y-8 gap-x-3 mb-10"
                  >
                    {homeState.units.map((unit) => {
                      if (unit.name === "No unit assigned") {
                        return null;
                      }
                      return (
                        <li className="flex w-full justify-center items-center ">
                          <button
                            className="flex w-full justify-center items-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm sm:text-sm"
                            type="button"
                            onClick={() => {
                              const element = document.getElementById(
                                unit.name
                              );
                              const headerOffset = 45;
                              const elementPosition =
                                element.getBoundingClientRect().top;
                              const offsetPosition =
                                elementPosition +
                                window.pageYOffset -
                                headerOffset;
                              cb(offsetPosition);
                            }}
                          >
                            {unit.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <div className={"absolute bottom-0 right-0 m-2"}>
                    <button
                      onClick={() => navigate("/units")}
                      type="button"
                      className="inline-flex items-center w-full justify-center mt-7 rounded-md border border-transparent bg-zinc-600 px-2 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Configure
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Root>
  );
}

export const UnitsView = () => {
  const [units, setUnits] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState();
  const [newName, setNewName] = useState("");
  const [toRemove, setToRemove] = useState("");
  const navigate = useNavigate();
  const ww = useSession();
  const c = useInjectToken(client, ww);
  const createNew = async () => {
    (await c).post(`/unit/${newName}`).then(() => {
      fetch();
      setOpen(false);
    });
  };
  const fetch = async () => {
    (await c).get(`/unit`).then(({ data }) => setUnits(data));
  };

  useEffect(() => {
    fetch();
  }, []);
  const reallyRemove = async () => {
    (await c).post(`/remove-unit/${toRemove}`).then(() => {
      fetch();
      setToRemove("");
      setOpenEdit();
    });
  };

  const remove = (name) => {
    setToRemove(name);
  };
  return (
    <div className={"relative"}>
      <UnitDeleteConfirm
        open={toRemove.length ? true : false}
        setOpen={() => {
          setToRemove("");
          setNewName("");
        }}
        confirm={() => reallyRemove()}
      />
      <button
        className="fixed bottom-20 right-0 m-4 items-center justify-center mt-7 text-xs rounded-md border border-transparent bg-indigo-600 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => setOpen(true)}
      >
        <p className={"text-white text-xs"}>🔥 Create new unit</p>
      </button>
      <New
        open={open}
        create={createNew}
        setOpen={setOpen}
        name={newName}
        setName={setNewName}
      />
      <EditOrRemove
        open={openEdit}
        create={createNew}
        setOpen={setOpenEdit}
        remove={remove}
        edit={(id) => navigate("/units/" + id)}
      />
      <div className="min-h-full">
        <div className="py-5">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Back text={"Home"} />
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-black flex-col flex">
                  <span>Configure Units</span>
                </h1>
              </div>
              <p className="mt-1 text-sm text-zinc-900">
                Units are used to group schedules.
              </p>
            </div>
          </motion.div>
          <main className="mx-5 mt-7">
            <div className="mt-6 flow-root">
              <ul role="list" className=" divide-zinc-800">
                {units.map((entry, i) => {
                  if (entry.name === "No unit assigned") {
                    return <></>;
                  }
                  return (
                    <motion.li
                      initial={{ x: -10, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: 0.2 + i / 20 }}
                      key={entry.start}
                      className={`mt-3 rounded-md
                                     bg-zinc-100 border-4 boxed border-black flex`}
                    >
                      <button
                        className="flex p-4 items-center w-full"
                        onClick={() => setOpenEdit(entry)}
                      >
                        <p className={`text-black `}>{entry?.name}</p>
                      </button>
                    </motion.li>
                  );
                })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export const UnitView = () => {
  const [unit, setUnit] = useState();
  const [schedules, setSchedules] = useState([]);
  const { _id } = useParams();
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const fetchSchedules = async () => {
    (await c).get(`/unit/${_id}`).then(({ data }) => setUnit(data));
    const now = moment(new Date());
    const scheds = await client.get(
      `/schedules/${now.year()}/${
        now.month() + 1
      }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}`
    );
    setSchedules(scheds.data);
  };

  const enable = async (schedule_id) => {
    (await c)
      .post(`/unit/${unit.name}/${schedule_id}`)
      .then(() => fetchSchedules());
  };
  const disable = async (schedule_id) => {
    (await c)
      .post(`/remove-unit/${unit.name}/${schedule_id}`)
      .then(() => fetchSchedules());
  };

  useEffect(() => {
    fetchSchedules();
  }, []);
  if (!unit) {
    return <></>;
  }
  return (
    <div className="min-h-full">
      <div className="py-5">
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Back text={"back"} />
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-slate-300 flex-col flex">
                <span>Edit {unit?.name}</span>
              </h1>
            </div>
            <p className="mt-1 text-sm text-slate-400">
              Here you can enable which schedules belong to {unit?.name}
            </p>
          </div>
        </motion.div>
        <main className="mx-5 mt-7">
          <div className="mt-6 flow-root">
            <ul role="list" className="-my-5 divide-y divide-zinc-800">
              {schedules.map((entry, i) => {
                const disabled = unit.schedules?.find(
                  (sched) => sched._id === entry._id
                )
                  ? false
                  : true;
                return (
                  <motion.li
                    initial={{ x: -10, opacity: 0 }}
                    animate={{ x: 0, opacity: disabled ? 0.5 : 1 }}
                    transition={{ delay: 0.2 + i / 20 }}
                    key={entry.start}
                    className={`py-4 px-4 rounded-md ${
                      disabled ? "opacity-50" : ""
                    } ${i % 2 === 0 ? "bg-zinc-900" : "bg-zinc-800"}`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className="min-w-0 flex-1">
                        <p className="truncate text-sm font-medium text-white">
                          {entry.name}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => {
                            if (disabled) {
                              enable(entry._id);
                            } else {
                              disable(entry._id);
                            }
                          }}
                          className="inline-flex items-center rounded-full  bg-zinc-700 px-2.5 py-0.5 text-sm font-medium leading-5 text-zinc-400 shadow-sm "
                        >
                          {disabled ? "Add" : "Remove"}
                        </button>
                      </div>
                    </div>
                  </motion.li>
                );
              })}
            </ul>
          </div>
        </main>
      </div>
    </div>
  );
};

export function New({ open, setOpen, name, setName, create }) {
  return (
    <Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-100 border-4 boxed border-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-black"
                    >
                      Create a new unit
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Choose a new name for your unit.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center flex-col">
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className="block py-3 px-3 mt-6 w-full rounded-md bg-zinc-900 text-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Name"
                  />
                  <button
                    onClick={create}
                    type="button"
                    className="inline-flex items-center w-full justify-center mt-7 rounded-md border border-transparent bg-indigo-600 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    🚀 Create unit
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Root>
  );
}

export function EditOrRemove({ open, setOpen, edit, remove }) {
  return (
    <Root show={open ? true : false} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen("")}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      Edit or delete a new unit
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Deleting a unit will completely remove it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center flex-col">
                  <button
                    onClick={() => edit(open._id)}
                    type="button"
                    className="inline-flex items-center w-full justify-center mt-7 rounded-md border border-transparent bg-slate-700 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-slate-500 focus:outline-none  focus:ring-offset-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => remove(open.name)}
                    type="button"
                    className="inline-flex items-center w-full justify-center mt-2 rounded-md border border-transparent bg-red-500 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Remove
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Root>
  );
}
