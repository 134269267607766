import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSchedulesState } from "./Home";

export default function Nextup({ onNavigate }) {
  const [nexts, setNexts] = useState([]);
  const schedState = useSchedulesState();

  useEffect(() => {
    const sorted = [...schedState.schedules]
      .reduce((acc, v) => {
        const f = [];
        v.final?.forEach((final) => {
          const val = { ...v };
          const bb = { ...final };
          const ss = new Date(bb.start);
          const dd = new Date();
          dd.setHours(ss.getUTCHours());
          dd.setMinutes(ss.getUTCMinutes());
          dd.setSeconds(ss.getUTCSeconds());
          dd.setDate(ss.getUTCDate());
          dd.setMonth(ss.getUTCMonth());
          val.n = { start: dd };
          val.disabled = bb.disabled || v.disabled;
          const now = new Date();
          // now.setHours(23);
          if (moment(now).isBefore(moment(dd))) {
            f.push(val);
          }
        });
        return [...acc, ...f];
      }, [])
      .sort((a, b) =>
        a.n?.start > b.n?.start ? 1 : b.n?.start > a.n?.start ? -1 : 0
      );
    setNexts(sorted);
  }, [schedState.schedules]);

  if (!nexts.length) {
    return <></>;
  }

  return (
    <>
      <motion.h1
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-xl font-bold leading-tight tracking-tight  text-white"
      >
        Next up for today
      </motion.h1>
      <ul
        role="list"
        className="grid grid-cols-4 gap-x-2 max-h-28 w-full mb-10 pt-5"
      >
        {[
          nexts[0] ? nexts[0] : null,
          nexts[1] ? nexts[1] : null,
          nexts[2] ? nexts[2] : null,
          nexts[3] ? nexts[3] : null,
        ]
          .filter((f) => f != null)
          .map((n, index) => {
            const s = new Date(n?.n.start);
            return (
              <div
                onClick={() => onNavigate("/schedule/" + n?._id)}
                key={index}
              >
                <motion.li
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: n.disabled ? 0.5 : 1, y: 0 }}
                  transition={{
                    delay: (index + 1) / 10,
                    type: "spring",
                    stiffness: 500,
                  }}
                  className="text-white bg-gray-900 h-24 p-4 border-2 border-gray-700 rounded-xl"
                >
                  <div className="flex flex-col w-full h-full">
                    <div className="flex h-full items-center">
                      {n.disabled ? (
                        <ExclamationTriangleIcon className="text-orange-400 w-5 h-5" />
                      ) : (
                        <CheckBadgeIcon className="text-green-400 w-5 h-5" />
                      )}
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="font-bold text-sm">{n?.name}</div>
                      <div className="text-xs">
                        {s.getHours()}:{s.getMinutes()}:{s.getSeconds()}
                      </div>
                    </div>
                  </div>
                </motion.li>
              </div>
            );
          })}
      </ul>
    </>
  );
}
