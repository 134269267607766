/* This example requires Tailwind CSS v2.0+ */

import { useSession } from "@clerk/clerk-react";
import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from "./Back";
import { useNotifyState } from "./Notification";
import client, { useInjectToken } from "./client";
export default function Sched() {
  const ns = useNotifyState();
  const navigate = useNavigate();

  const { _id } = useParams();
  const [entry, setEntry] = useState([]);
  const [name, setName] = useState("");
  const [exceptions, setExceptions] = useState([]);
  const [overallDisabled, setOverallDisabled] = useState(false);
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const fetchall = async () => {
    const now = moment(new Date());
    (await c)
      .get(
        `/schedules/${now.year()}/${
          now.month() + 1
        }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}/${_id}`
      )
      .then(({ data }) => {
        if (data && data.final?.length) {
          setName(data.name);
          setEntry(data.final);
          setExceptions(data.exceptions);
          setOverallDisabled(data.disabled);
        }
      });
  };

  useEffect(() => {
    fetchall();
  }, []);

  const disable = async (open_utc) => {
    const now = moment(new Date());
    (await c)
      .post(
        `/schedules/disable/${now.year()}/${
          now.month() + 1
        }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}/${_id}/${open_utc}`
      )
      .then(({ data }) => {
        setExceptions(data.exceptions);
        const start = new Date(open_utc);
        const normalStartTime = moment(new Date());
        normalStartTime.set("h", start.getUTCHours());
        normalStartTime.set("m", start.getUTCMinutes());
        normalStartTime.set("s", start.getUTCSeconds());
        normalStartTime.set("month", start.getUTCMonth());
        normalStartTime.set("date", start.getUTCDate());
        ns.setNotification({
          title: `Schedule disabled`,
          sub: normalStartTime.format("DD/MM/YYYY - HH:mm:ss"),
          Icon: () => (
            <ExclamationTriangleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
      });
  };

  const enable = async (open_utc) => {
    const now = moment(new Date());
    (await c)
      .post(
        `/schedules/enable/${now.year()}/${
          now.month() + 1
        }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}/${_id}/${open_utc}`
      )
      .then(({ data }) => {
        setExceptions(data.exceptions);
        const start = new Date(open_utc);
        const normalStartTime = moment(new Date());
        normalStartTime.set("h", start.getUTCHours());
        normalStartTime.set("m", start.getUTCMinutes());
        normalStartTime.set("s", start.getUTCSeconds());
        normalStartTime.set("month", start.getUTCMonth());
        normalStartTime.set("date", start.getUTCDate());
        ns.setNotification({
          title: `Schedule enabled again`,
          sub: normalStartTime.format("DD/MM/YYYY - HH:mm:ss"),
          Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
        });
      });
  };

  const toggleOverallDisable = async () => {
    const now = moment(new Date());
    (await c)
      .get(
        `/schedules/${_id}/disable/${now.year()}/${now.month()}/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}`
      )
      .then(({ data }) => {
        fetchall();
      });
  };

  return (
    <>
      <div className="min-h-full">
        <div className="py-5">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Back text={"Home"} />
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-white flex-col flex">
                  <span>Full schedule - {name}</span>{" "}
                </h1>
                <button
                  className="text-white p-3 rounded-lg text-sm font-bold bg-green-400"
                  onClick={() => navigate("/history/" + _id)}
                >
                  HISTORY
                </button>
              </div>
              <p className="mt-1 text-sm text-gray-200">
                Here you can view the full schedule. You can also disable
                schedules.
              </p>
              <>
                {overallDisabled ? (
                  <div className="flex items-center px-4 py-3 bg-orange-400 my-6 rounded-md justify-between">
                    <div className="flex items-center">
                      <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-white" />
                      <p className="text-white">
                        This whole schedule has been disabled
                      </p>
                    </div>
                    <button
                      onClick={toggleOverallDisable}
                      className="bg-orange-500 text-white p-3 rounded-lg text-sm"
                    >
                      enable again
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center px-4 py-3 bg-green-400 my-6 rounded-md justify-between">
                    <div className="flex items-center">
                      <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-white" />
                      <p className="text-white">
                        This schedule has been enabled
                      </p>
                    </div>
                    <button
                      onClick={toggleOverallDisable}
                      className="bg-green-500 text-white p-3 rounded-lg text-sm"
                    >
                      disable
                    </button>
                  </div>
                )}
              </>
            </div>
          </motion.div>
          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div className="mt-6 flow-root">
              <ul role="list" className="">
                {entry
                  .sort((a, b) => new Date(b.start) - new Date(a.start))
                  .map(({ entry }, i) => {
                    const start = new Date(entry.start);
                    const normalStartTime = moment(new Date());
                    normalStartTime.set("h", start.getUTCHours());
                    normalStartTime.set("m", start.getUTCMinutes());
                    normalStartTime.set("s", start.getUTCSeconds());
                    normalStartTime.set("month", start.getUTCMonth());
                    normalStartTime.set("date", start.getUTCDate());

                    const stop = new Date(entry.stop);
                    const normalSopTime = moment(new Date());
                    normalSopTime.set("h", stop.getUTCHours());
                    normalSopTime.set("m", stop.getUTCMinutes());
                    normalSopTime.set("s", stop.getUTCSeconds());
                    normalSopTime.set("month", stop.getUTCMonth());
                    normalSopTime.set("date", stop.getUTCDate());
                    const disabled =
                      exceptions.includes(entry.start) || overallDisabled;

                    const now = moment(new Date());
                    return (
                      <motion.li
                        initial={{ x: -10, opacity: 0 }}
                        animate={{ x: 0, opacity: disabled ? 0.5 : 1 }}
                        transition={{ delay: 0.2 + i / 20 }}
                        key={entry.start}
                        className={`py-2 px-4 border-2 mt-4 border-gray-700 ${
                          disabled ? "opacity-50" : ""
                        } bg-gray-900`}
                      >
                        <div className="flex items-center space-x-4">
                          {disabled ? (
                            <ExclamationTriangleIcon className="text-orange-400 w-5 h-5" />
                          ) : now.isAfter(normalStartTime) ? (
                            <CheckBadgeIcon className="text-green-400 w-5 h-5" />
                          ) : (
                            <ClockIcon className="text-blue-400 w-5 h-5" />
                          )}

                          <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-white">
                              {normalStartTime.format("DD/MM/YYYY - HH:mm:ss")}{" "}
                            </p>
                            <p className="truncate text-sm text-gray-200">
                              {normalSopTime.format("DD/MM/YYYY - HH:mm:ss")}
                            </p>
                            <p className="truncate text-sm font-medium text-white">
                              {moment
                                .duration(normalSopTime.diff(normalStartTime))
                                .as("seconds")}{" "}
                              Seconds
                            </p>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                if (disabled) {
                                  enable(entry.start);
                                } else {
                                  disable(entry.start);
                                }
                              }}
                              className="inline-flex items-center rounded-full  bg-zinc-600 px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm "
                            >
                              {disabled ? "Enable" : "Disable"}
                            </button>
                          </div>
                        </div>
                      </motion.li>
                    );
                  })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
