/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useSchedulesState } from "./Home";
import moment from "moment";
import {
  AdjustmentsHorizontalIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function Info({ open, setOpen }) {
  const schedState = useSchedulesState();
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden w-full rounded-lg bg-zinc-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <ul>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">Name</p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.name || "..."}
                        </p>
                      </div>
                    </li>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          Starts at
                        </p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.open_utc
                            ? moment(schedState.info?.open_utc).format(
                                "HH:mm:ss"
                              )
                            : "..."}
                        </p>
                      </div>
                    </li>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          Repeats every
                        </p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.every ? (
                            <>{schedState.info?.every.hour} hours</>
                          ) : (
                            "..."
                          )}
                        </p>
                      </div>
                    </li>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          Max time
                        </p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.max_utc
                            ? moment(schedState.info?.max_utc).format(
                                "HH:mm:ss"
                              )
                            : "..."}
                        </p>
                      </div>
                    </li>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          Runs for
                        </p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.close_utc &&
                          schedState.info?.open_utc ? (
                            <div>
                              {moment
                                .duration(
                                  moment(schedState.info?.close_utc).diff(
                                    moment(schedState.info?.open_utc)
                                  )
                                )
                                .asSeconds()}{" "}
                              seconds
                            </div>
                          ) : (
                            "..."
                          )}
                        </p>
                      </div>
                    </li>
                    <li className="flex py-3">
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">Pin</p>
                        <p className="text-sm text-slate-400">
                          {schedState.info?.pin ? schedState.info?.pin : "..."}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Link to={"/edit/" + schedState.info?._id}>
                    <button
                      type="button"
                      className="inline-flex items-center w-full justify-center rounded-md border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm  sm:col-start-2 sm:text-sm"
                      onClick={() => setOpen()}
                    >
                      <PencilSquareIcon
                        className="h-5 w-5 text-white mr-2 "
                        aria-hidden="true"
                      />{" "}
                      edit
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md  bg-zinc-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-50  sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen()}
                    ref={cancelButtonRef}
                  >
                    close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
