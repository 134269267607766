import create from "zustand";
import {Fragment} from "react";
import {Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";

export const useNotifyState = create((set, get) => {
    return {
        notification: null,
        timer: null,
        clear: () => set({notification: null, timer: null}),
        setNotification: (title) => {
            clearTimeout(get().timer);
            const timer = setTimeout(() => {
                get().clear();
            }, 2000);
            set({timer, notification: title});
        },
    };
});

export const Notifications = () => {
    const ns = useNotifyState();
    const Icon = ns.notification?.Icon;
    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6"
            style={{zIndex: 10000000000000}}
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-start">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                    show={ns.notification != null}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-zinc-700 shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">{Icon ? <Icon/> : null}</div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                    <p className="text-sm font-medium text-white">
                                        {ns.notification?.title}
                                    </p>
                                    <p className="mt-1 text-sm text-zinc-400">
                                        {ns.notification?.sub}
                                    </p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-zinc-900 text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => {
                                            ns.clear();
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};
