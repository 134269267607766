/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useSession, useUser } from "@clerk/clerk-react";
import { Dialog, Transition } from "@headlessui/react";
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PlayPauseIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  PlayIcon,
  StopIcon,
} from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { BarChart } from "./BarChart";
import { useNotifyState } from "./Notification";
import { Picker } from "./Picker";
import client, { useInjectToken } from "./client";

const round = (num) => Math.round(num * 100) / 100;

const activity = [
  {
    id: 1,
    type: "created",
    person: { name: "Chelsea Hagon" },
    date: "7d ago",
    dateTime: "2023-01-23T10:32",
  },
  {
    id: 2,
    type: "edited",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:03",
  },
  {
    id: 3,
    type: "sent",
    person: { name: "Chelsea Hagon" },
    date: "6d ago",
    dateTime: "2023-01-23T11:24",
  },
  {
    id: 4,
    type: "commented",
    person: {
      name: "Chelsea Hagon",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    comment:
      "Called client, they reassured me the invoice would be paid by the 25th.",
    date: "3d ago",
    dateTime: "2023-01-23T15:56",
  },
  {
    id: 5,
    type: "viewed",
    person: { name: "Alex Curren" },
    date: "2d ago",
    dateTime: "2023-01-24T09:12",
  },
  {
    id: 6,
    type: "paid",
    person: { name: "Alex Curren" },
    date: "1d ago",
    dateTime: "2023-01-24T09:20",
  },
];
const moods = [
  {
    name: "Excited",
    value: "excited",
    icon: FireIcon,
    iconColor: "text-white",
    bgColor: "bg-red-500",
  },
  {
    name: "Loved",
    value: "loved",
    icon: HeartIcon,
    iconColor: "text-white",
    bgColor: "bg-pink-400",
  },
  {
    name: "Happy",
    value: "happy",
    icon: FaceSmileIcon,
    iconColor: "text-white",
    bgColor: "bg-green-400",
  },
  {
    name: "Sad",
    value: "sad",
    icon: FaceFrownIcon,
    iconColor: "text-white",
    bgColor: "bg-yellow-400",
  },
  {
    name: "Thumbsy",
    value: "thumbsy",
    icon: HandThumbUpIcon,
    iconColor: "text-white",
    bgColor: "bg-blue-500",
  },
  {
    name: "I feel nothing",
    value: null,
    icon: XMarkIcon,
    iconColor: "text-gray-400",
    bgColor: "bg-transparent",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Timeline({
  year,
  week,
  weekly,
  setTasks,
  start: s,
  stop: ss,
  startStopLoading,
  enable,
  disable,
}) {
  const u = useUser();
  const ns = useNotifyState();

  const ww = useSession();
  const c = useInjectToken(client, ww);
  const [timeline, setTimeline] = useState({});
  const [selected, setSelected] = useState(moods[5]);
  const [taskActionLoading, setTaskActionLoading] = useState(false);
  const [timeLineLoading, setTimeLineLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [comment, setComment] = useState({});
  const fetchTimelineByDay = async (w) => {
    const searchParams = new URLSearchParams({ year: year });
    (await c)
      .get(`timeline/my/${w || week}?${searchParams}`)
      .then(({ data }) => {
        setTimeline(data);
        const cmap = Object.keys(data[u.user.id]).reduce((acc, val) => {
          return { ...acc, [val]: data[u.user.id][val].comment || "" };
        }, {});
        setComment(cmap);
      });
  };

  const start = async () => {
    await s().catch(() => {
      ns.setNotification({
        title: `Something went wrong`,
        sub: "Try again or refresh the page and try again",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
    });
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const stop = async () => {
    await ss().catch(() => {
      ns.setNotification({
        title: `Something went wrong`,
        sub: "Try again or refresh the page and try again",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
    });
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  useEffect(() => {
    setTimeLineLoading(true);
    fetchTimelineByDay(week);
    setTimeLineLoading(false);
    setInitialLoading(false);
  }, [week, year]);

  const complete = async (_id, date) => {
    setTaskActionLoading(true);
    await (await c)
      .post(`tasks/${_id}/${date}/complete/${moment().toISOString()}`)
      .catch(() => {
        setTaskActionLoading(false);
        ns.setNotification({
          title: `Something went wrong`,
          sub: "Try again or refresh the page and try again",
          Icon: () => (
            <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
      });
    setTaskActionLoading(false);
    fetchTimelineByDay(week);
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const uncomplete = async (_id, date) => {
    setTaskActionLoading(true);
    await (await c).post(`tasks/${_id}/${date}/uncomplete`).catch(() => {
      setTaskActionLoading(false);
      ns.setNotification({
        title: `Something went wrong`,
        sub: "Try again or refresh the page and try again",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
    });
    setTaskActionLoading(false);
    fetchTimelineByDay(week);
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const saveComment = async (date) => {
    (await c).post(`comment`, { comment: comment[date], date });
    ns.setNotification({
      title: `Comment updated!`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const weekdayMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const check = async (checked, _id, day) => {
    try {
      if (!checked) {
        await complete(_id, day);
      } else {
        await uncomplete(_id, day);
      }
    } catch (e) {
      ns.setNotification({
        title: `Something went wrong`,
        sub: "Try again or refresh the page and try again",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
    }
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const pause = async (paused) => {
    setTaskActionLoading(true);
    try {
      if (paused) {
        await enable();
      } else {
        await disable();
      }
      fetchTimelineByDay(week);
    } catch (e) {
      setTaskActionLoading(false);
      ns.setNotification({
        title: `Something went wrong`,
        sub: "Try again or refresh the page and try again",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
    }
    setTaskActionLoading(false);
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  const today = useMemo(() => {
    if (!u.user) {
      return { myDay: {}, todayKey: "" };
    }
    const myTimeline = timeline[u.user.id];
    if (!myTimeline) {
      return { myDay: {}, todayKey: "" };
    }
    const todayKey = Object.keys(myTimeline).find(
      (d) => moment(d).format("dddd") === moment().format("dddd")
    );
    const myDay = myTimeline[todayKey];
    return { myDay, todayKey };
  }, [timeline, u.user]);

  const {
    activity,
    user,
    tasks: tt,
    currentlyPaused,
    comment: cmm,
  } = today?.myDay || {
    activity: [],
    user: {},
    tasks: [],
    currentlyPaused: false,
    comment: {},
  };

  const tasks = tt?.tasks || [];

  const hasCurrentlyActiveOne = today?.myDay?.active;

  return (
    <>
      {/* <RequestModal
        open={true}
        setOpen={() => null}
        weekly={timeline[u.user?.id]}
      /> */}
      <div
        id={moment().format("dddd")}
        className={
          hasCurrentlyActiveOne
            ? `bg-gray-900 my-10 p-5 mb-3  border-2 ${
                !currentlyPaused ? "border-indigo-600" : "border-orange-600"
              } rounded-md`
            : "my-10 border-2 rounded-md p-5 bg-gray-900 border-gray-800"
        }
      >
        <div className="flex w-full font-bold flex-col justify-between border-b border-gray-700 pb-2 mb-5 items-center">
          {hasCurrentlyActiveOne && (
            <button
              onClick={() => pause(currentlyPaused)}
              className={`flex w-full items-center border ${
                currentlyPaused
                  ? "border-orange-600 bg-orange-500"
                  : "bg-gray-800 border-none"
              }  mb-3 text-white p-3 rounded-md justify-between`}
            >
              {taskActionLoading ? (
                <ArrowPathIcon className="text-white animate-spin w-5 h-5" />
              ) : currentlyPaused ? (
                <PlayPauseIcon className="h-6 w-6 mr-3 text-white" />
              ) : (
                <PlayPauseIcon className="h-6 w-6 mr-3 text-white" />
              )}
              <p>{currentlyPaused ? "Resume shift" : "Pause shift"}</p>
            </button>
          )}
          <button
            onClick={
              hasCurrentlyActiveOne
                ? async () => {
                    await stop();
                    fetchTimelineByDay(week);
                  }
                : async () => {
                    await start();
                    fetchTimelineByDay(week);
                  }
            }
            className="flex w-full font-bold items-center border border-indigo-600 bg-indigo-500 mb-5 text-white p-3 rounded-md justify-between"
          >
            {startStopLoading ? (
              <ArrowPathIcon className="text-white animate-spin w-5 h-5" />
            ) : hasCurrentlyActiveOne ? (
              <StopIcon className="h-6 w-6 mr-3 text-white" />
            ) : (
              <PlayIcon className="h-6 w-6 mr-3 text-white" />
            )}
            <p>
              {hasCurrentlyActiveOne ? "Stop current shift" : "Start new shift"}
            </p>
          </button>
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col justify-between mb-5">
              <p className="font-light text-xs mt-3">
                {moment(today.todayKey).format("MM-DD-YYYY")}{" "}
                {today && "// Today"}
              </p>{" "}
              <p className="font-bold ">
                {moment(today.todayKey).format("dddd")}
              </p>{" "}
            </div>
          </div>
        </div>
        <div className="flex  flex-col justify-between mb-5">
          {/* {today && !hasCurrentlyActiveOne ? (
                        <div className="flex items-center border border-red-500 bg-red-300 mb-3 text-white p-3 rounded-md justify-between">
                          <div className="flex items-center">
                            <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-red-500" />
                            <p className="text-red-500 text-xs">
                              You have to check in to complete tasks
                            </p>
                          </div>
                        </div>
                      ) : null} */}
          <p className="font-bold ">Tasks</p>
          <p className="font-light text-xs mt-1">
            Here you can see all the tasks you have to do this day.
          </p>{" "}
        </div>
        <ul
          role="list"
          className="space-y-6 font-normal mb-5 border-b border-gray-700 pb-5"
        >
          {(tasks || [])?.map((task, activityItemIdx) => {
            const completed = task.completedByAssignee.some((c) => {
              return (
                c.id === u.user.id &&
                dayjs(today.todayKey)
                  .tz("Europe/Amsterdam")
                  .format("DD/MM/YYYY dddd") ===
                  dayjs(c.day).tz("Europe/Amsterdam").format("DD/MM/YYYY dddd")
              );
            });
            const findIfThisDay = task.days.find(
              (d) =>
                moment(d).format("DD/MM/YYYY dddd") ===
                moment(today.todayKey).format("DD/MM/YYYY dddd")
            );
            if (!findIfThisDay) return null;
            return (
              <li className="flex items-center justify-between gap-x-6 ">
                <div className="flex min-w-0 gap-x-4">
                  <div
                    className={`relative flex h-12 w-12 flex-none items-center justify-center bg-gray-900`}
                  >
                    {completed ? (
                      <CheckCircleIcon
                        className="h-12 w-12 text-indigo-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <ExclamationCircleIcon
                        className="h-12 w-12 text-orange-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-white">
                      {task.description}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-white">
                      {!completed ? "Uncompleted" : "Completed"}
                    </p>
                  </div>
                </div>
                {today && (
                  <button
                    onClick={
                      completed
                        ? () => check(true, task._id, today.todayKey)
                        : () => check(false, task._id, today.todayKey)
                    }
                    disabled={
                      !hasCurrentlyActiveOne ||
                      moment(today.todayKey).format("dddd") !==
                        moment().format("dddd") ||
                      week !==
                        (moment().day() === 0
                          ? moment().week() - 1
                          : moment().week()) ||
                      !hasCurrentlyActiveOne
                    }
                    className="rounded-full disabled:bg-slate-50 disabled:text-slate-500 bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {taskActionLoading && (
                      <ArrowPathIcon className="text-black animate-spin w-5 h-5" />
                    )}
                    {!completed && !taskActionLoading
                      ? "Complete"
                      : !taskActionLoading
                      ? "Incomplete"
                      : null}
                  </button>
                )}
              </li>
              // <li
              //   //   key={activityItem.time}
              //   className="relative flex gap-x-4"
              // >
              //   <div
              //     className={classNames(
              //       activityItemIdx === tasks.length - 1 ? "h-8" : "",
              //       "absolute left-0 top-0 flex w-6 justify-center items-center"
              //     )}
              //   >
              //     <div className="w-px bg-gray-200" />
              //   </div>
              //   {
              //     <>
              //       <div
              //         className={`relative flex h-8 w-6 flex-none items-center justify-center ${
              //           today ? "bg-slate-100" : "bg-white"
              //         }`}
              //       >
              //         {completed ? (
              //           <CheckCircleIcon
              //             className="h-6 w-6 text-indigo-600"
              //             aria-hidden="true"
              //           />
              //         ) : (
              //           <ExclamationCircleIcon
              //             className="h-6 w-6 text-orange-600"
              //             aria-hidden="true"
              //           />
              //         )}
              //       </div>
              //       <div className="flex items-center">
              //         <p className=" text-xs leading-5 text-gray-500">
              //           <span className="font-medium text-gray-900">
              //             <span className="font-bold">
              //               {task.description}
              //             </span>{" "}
              //           </span>{" "}
              //         </p>
              //         <button
              //           type="button"
              //           onClick={
              //             completed
              //               ? () => check(true, task._id, day)
              //               : () => check(false, task._id, day)
              //           }
              //           disabled={
              //             moment(day).format("dddd") !==
              //               moment().format("dddd") ||
              //             week !==
              //               (moment().day() === 0
              //                 ? moment().week() - 1
              //                 : moment().week()) ||
              //             !hasCurrentlyActiveOne
              //           }
              //           className="ml-7  items-center  justify-center rounded-md border-transparent bg-indigo-600 px-4 py-2 text-xs font-medium text-white shadow-sm disabled:bg-indigo-200"
              //         >
              //           {/* {completed ? "Uncomplete" : "Complete"} */}
              //           <CheckBadgeIcon className=" w-4 h-4 text-white" />
              //         </button>
              //       </div>
              //     </>
              //   }
              // </li>
            );
          })}
        </ul>
        <div className="flex flex-col justify-between mb-5">
          <p className="font-bold ">Shifts</p>{" "}
          <p className="font-light text-xs mt-1">
            Here you can see all the shifts you have worked on this day.
          </p>{" "}
        </div>
        {!activity?.length && (
          <p className="mb-5 font-normal text-xs">
            No <span className="font-bold">shifts</span> yet
          </p>
        )}
        <ul role="list" className="space-y-6 font-normal">
          {activity?.map((activityItem, activityItemIdx) => (
            <li key={activityItem.time} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === activity.length - 1 ? "h-6" : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-900" />
              </div>
              {
                <>
                  <div
                    className={`relative flex h-8 w-6 flex-none items-center justify-center bg-gray-900`}
                  >
                    {activityItem.type === "paid" ? (
                      <CheckCircleIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-900 ring-1 ring-gray-300" />
                    )}
                  </div>
                  <p className="flex-auto items-center py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-200">
                      <span className="font-bold">{user.data?.firstName}</span>{" "}
                      {activityItem.name}
                    </span>{" "}
                    {/* {activityItem.type} the invoice. */}
                  </p>
                  <time
                    dateTime={activityItem.dateTime}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {activityItem.time}
                  </time>
                </>
              }
            </li>
          ))}
        </ul>
        <div className="flex flex-col justify-between mb-5 mt-5">
          <p className="font-bold ">Remarks</p>{" "}
          <p className="font-light text-xs mt-1">
            You can leave a comment about today here.
          </p>{" "}
        </div>
        <div action="#" className="relative mt-4">
          <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-700 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={3}
              name="comment"
              id="comment"
              className="block bg-white w-full resize-none border-0 bg-transparent py-2 text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              onChange={(e) =>
                setComment((p) => ({
                  ...p,
                  [today.todayKey]: e.target.value,
                }))
              }
              value={comment[today.todayKey] || ""}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center"></div>
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={() => saveComment(today.todayKey)}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Update/save comment
              </button>
            </div>
          </div>
        </div>
      </div>
      <>
        <div className="flex flex-col justify-between mb-5">
          <p className="font-bold ">Your week</p>{" "}
          <p className="font-light text-xs mt-1">
            Here you can see how much you have worked this week.
          </p>{" "}
        </div>
        <p className="font-light text-lg mt-1">
          {" "}
          <span className="font-normal text-xs">
            Total hours this week:
          </span>{" "}
          <br />{" "}
          {round(
            moment
              .duration(
                weekly?.shifts?.reduce((a, b) => {
                  return a + b.totalShiftTime;
                }, 0)
              )
              .asHours()
          )}{" "}
          hours
        </p>
        <p className="font-light text-lg mt-1">
          {" "}
          <span className="font-normal text-xs">
            Total break this week:
          </span>{" "}
          <br />{" "}
          {round(
            moment
              .duration(
                weekly?.shifts?.reduce((a, b) => {
                  return a + b.totalPauseTime;
                }, 0)
              )
              .asHours()
          )}{" "}
          hours
        </p>
      </>
      <div style={{ height: "400px" }}>
        <BarChart hours={weekly?.week?.worked} breaks={weekly?.week?.breaks} />
      </div>
      {/*}
      <button
        className={`flex w-full font-bold items-center border bg-orange-600 border-none mt-10  mb-3 text-white p-3 rounded-md justify-between`}
      >
        <p>Request change</p>
        <ExclamationTriangleIcon
          className="h-6 w-6 text-white"
          aria-hidden="true"
        />
      </button>
      <p className="font-light text-xs ml-1">
        If there is something wrong, you can request a hard change here for any
        day of the week.
      </p>{" "} */}
    </>
  );
}

export function RequestModal({ open, setOpen, weekly }) {
  const [day, setDay] = useState(null);
  const [week, setWeek] = useState(null);

  const [weekDays, setWeekDays] = useState([]);
  useEffect(() => {
    setWeekDays(Object.keys(weekly || {}));
  }, [weekly]);

  useEffect(() => {
    setWeek(weekly);
  }, [weekly]);

  useEffect(() => {}, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12 text-white">
                    <h2 className="text-base font-semibold leading-7 text-white">
                      Request change
                    </h2>

                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm font-medium leading-6 text-gray-300 mt-5"
                      >
                        Day of the week
                      </label>
                      <select
                        id="location"
                        name="location"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-300 ring-1 ring-inset ring-gray-800 focus:ring-2 focus:ring-gray-800 sm:text-sm sm:leading-6"
                        defaultValue={moment().startOf("day").toISOString()}
                      >
                        {weekDays.map((d) => {
                          return (
                            <option value={moment(d).toISOString()}>
                              {moment(d).format("dddd")}
                            </option>
                          );
                        })}
                      </select>
                      <div className="flex flex-col justify-between mt-5">
                        <p className="font-bold ">Your day</p>{" "}
                        <p className="font-light text-xs mt-1">
                          Here you can see how much you have worked this day. If
                          this is incorrect, yo ucan change it here.
                        </p>{" "}
                      </div>
                      <p className="font-light text-lg mt-3">
                        {" "}
                        <span className="font-normal text-xs">
                          Total hours this day:
                        </span>{" "}
                        <br />{" "}
                        <div className="flex gap-x-2 items-end">
                          <Picker
                            setValue={(v1, v2, v3) => {
                              console.log(v1, v2, v3);
                            }}
                            value={10}
                            mode="hours"
                          />
                          {round(
                            moment
                              .duration(
                                0
                                // weekly?.shifts?.reduce((a, b) => {
                                //   return a + b.totalShiftTime;
                                // }, 0)
                              )
                              .asHours()
                          )}{" "}
                          hours
                        </div>
                      </p>
                      <p className="font-light text-lg mt-1">
                        {" "}
                        <span className="font-normal text-xs">
                          Total break this day:
                        </span>{" "}
                        <br />{" "}
                        <div className="flex gap-x-2 items-end">
                          <Picker
                            setValue={(v1, v2, v3) => {
                              console.log(v1, v2, v3);
                            }}
                            value={10}
                            mode="hours"
                          />
                          {round(
                            moment
                              .duration(
                                0
                                // weekly?.shifts?.reduce((a, b) => {
                                //   return a + b.totalPauseTime;
                                // }, 0)
                              )
                              .asHours()
                          )}{" "}
                          hours
                        </div>
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Close
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
