import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Worked hours graph per week",
      color: "white",
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const labels = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Worked hours",
      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      backgroundColor: "rgb(255, 99, 132)",
    },
  ],
};

export function BarChart({ hours, breaks }) {
  return (
    <Bar
      options={options}
      data={{
        labels,
        datasets: [
          {
            label: "worked hours",
            backgroundColor: "rgb(79, 70, 229)",
            data: hours,
          },
          {
            label: "Breaks",
            backgroundColor: "rgba(79, 70, 229, 0.5)",
            data: breaks,
          },
        ],
      }}
    />
  );
}
