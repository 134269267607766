/* This example requires Tailwind CSS v2.0+ */

import { useSession } from "@clerk/clerk-react";
import { motion } from "framer-motion";
import moment, { utc } from "moment";
import { tz } from "moment-timezone";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import create from "zustand";
import Back from "./Back";
import Dropdown from "./Dropdown";
import EditConfirm from "./EditConfirm";
import EditDeleteConfirm from "./EditDeleteConfirm";
import { Picker } from "./Picker";
import client, { useInjectToken } from "./client";

const defaultEditState = {
  parents: [],
  isParent: false,

  start: [0, 0, 0],
  for: [0, 0, 0],
  every: 0,
  max: { i: 0, nextDay: false },
  pin: 0,
  name: "",
  dependency: null,
  before: 0,
  raspberry: "",
  after: 0,
};

export const raspBerrys = [{ name: "Main raspberry", id: 1 }];

export const useEditStore = create((set) => ({
  ...defaultEditState,
  setParents: (parents) => set({ parents }),
  setIsParent: (b) => set({ isParent: b }),
  setStart: (v1, v2, v3) =>
    set((prev) => ({
      start: [
        v1 === -1 ? prev.start[0] : v1,
        v2 === -1 ? prev.start[1] : v2,
        v3 === -1 ? prev.start[2] : v3,
      ],
    })),
  setFor: (v2, v3) =>
    set((prev) => ({
      for: [0, v2 === -1 ? prev.for[1] : v2, v3 === -1 ? prev.for[2] : v3],
    })),
  setEvery: (every) => set({ every }),
  setMax: (max) => set({ max }),
  setPin: (pin) => set({ pin }),
  setName: (name) => set({ name }),
  setDependency: (dependency) => set({ dependency }),
  setBefore: (before) => set({ before }),
  setAfter: (after) => set({ after }),
  reset: () => set(defaultEditState),
  setRaspberry: (raspberry) => set({ raspberry }),
}));

export default function Edit() {
  const editState = useEditStore();
  const { _id } = useParams();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const getParents = async () => {
    (await c).get(`/parents`).then(({ data }) => editState.setParents(data));
  };

  useEffect(() => {
    getParents();
  }, []);

  const deleteSched = async () => {
    (await c).post(`/delete/` + _id).then(() => navigate("/"));
  };

  const submit = async () => {
    const {
      name,
      pin,
      before,
      after,
      isParent,
      start,
      for: duration,
      max,
      every,
    } = editState;
    const now = tz("Europe/Amsterdam");
    let nowUtc = moment().utc();

    nowUtc.set("years", now.year());
    nowUtc.set("month", now.month());
    nowUtc.set("date", now.date());
    nowUtc.set("hours", start[0]);
    nowUtc.set("minute", start[1]);
    nowUtc.set("second", start[2]);
    nowUtc.set("milliseconds", 0);

    if (isParent) {
      (await c)
        .post(`/parents-update`, {
          _id,
          name: name,
          pin: pin,
          before: before,
          after: after,
          raspberry: editState.raspberry,
        })
        .then(() => navigate("/"));
    } else {
      (await c)
        .post(`/schedules-update`, {
          _id: _id,
          name: name,
          pin: pin,
          start: nowUtc.toDate().toISOString(),
          duration: editState.for[1] * 60 + editState.for[2],
          max: max,
          everyHour: every,
          dependency: editState.dependency ? editState.dependency._id : null,
          raspberry: editState.raspberry,
        })
        .then(() => navigate("/"));
    }
  };

  const view = async () => {
    editState.reset();
    client.get(`/schedules/view/` + _id).then(({ data }) => {
      editState.setIsParent(data.parent);
      editState.setName(data.name);
      editState.setPin(data.pin);
      if (data.raspberry) {
        editState.setRaspberry(data.raspberry);
      }
      if (data.parent) {
        editState.setBefore(data.dependency_run_extra.before.seconds);
        editState.setAfter(data.dependency_run_extra.after.seconds);
      } else {
        const open = utc(data.open_utc);
        const close = utc(data.close_utc);
        const max = utc(data.max_utc);
        const duration = moment.duration(close.diff(open));

        editState.setStart(open.hour(), open.minute(), open.second());
        let forMinutes = 0;
        let forSeconds = duration.asSeconds();
        forMinutes = Math.round(forSeconds / 60);
        forSeconds = forSeconds % 60;
        editState.setFor(forMinutes, forSeconds);
        editState.setEvery(data.every.hour);
        if (max.date() > close.date()) {
          editState.setMax(max.hour() + 24 - 1);
        } else {
          editState.setMax(max.hour() - 1);
        }
        if (data.dependency?.name) {
          editState.setDependency(data.dependency);
        }
      }
    });
  };

  useEffect(() => {
    view();
  }, []);

  return (
    <>
      <EditConfirm
        open={showConfirm}
        setOpen={setShowConfirm}
        confirm={submit}
      />
      <EditDeleteConfirm
        open={showDeleteConfirm}
        setOpen={setshowDeleteConfirm}
        confirm={deleteSched}
      />
      <div className="min-h-ful">
        <div className="py-5">
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Back text={"Home"} />
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-white flex-col flex">
                  <span>Edit {editState.name}</span>
                </h1>
                <button
                  onClick={() => setshowDeleteConfirm(true)}
                  type="button"
                  className="inline-flex w-16 items-center justify-center py-2 rounded-md border border-transparent bg-red-600 px-4 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <p className="text-xs text-white">Remove</p>
                </button>
              </div>
              <p className="mt-1 text-sm text-gray-200">
                Here you can create a new schedule. Make sure not to forget a
                dependency if there is any.
              </p>
            </div>
          </motion.div>
          <main>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {" "}
              <motion.div
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 100, opacity: 0 }}
                transition={{ delay: 0.05 }}
                className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
              >
                <h3 className=" flex items-center truncate text-medium font-bold text-gray-200">
                  Name
                </h3>
                <p className="mt-1 text-sm text-gray-200">
                  The reference name of the schedule. This will be used in the
                  interface instead of the pin.
                </p>
                <div className="flex items-end">
                  <input
                    onChange={(e) => editState.setName(e.target.value)}
                    value={editState.name}
                    className="block py-3 px-3 mt-6 w-full rounded-md bg-gray-900 border-gray-700 border-2 text-white font-bold shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Cel 5"
                  />
                </div>
              </motion.div>
              {!editState.isParent ? (
                <>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.1 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      Start
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines to moment at which the schedule should
                      start everyday. 17:00:00 means it will run at 17 hours, 0
                      minutes and 0 seconds.
                    </p>
                    <Picker
                      setValue={(v1, v2, v3) => editState.setStart(v1, v2, v3)}
                      value={editState.start}
                    />
                  </motion.div>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.2 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      For
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines how long the schedule should run. 3 will
                      result in a 3 second run. For a start of 17:00:00 it will
                      run till 17:00:03
                    </p>
                    <div className="flex items-end">
                      <Picker
                        mode="minuteseconds"
                        setValue={(v1, v2, v3) => {
                          editState.setFor(v2, v3);
                        }}
                        value={editState.for}
                      />{" "}
                      <p className="ml-3 text-sm text-gray-200">
                        minute/Seconds
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.3 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      Every
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines the repeat rate. With a value of 2,
                      starting at 17:00:00, it will run at 17:00:00, 19:00:00,
                      21:00:00 and so fort.
                    </p>
                    <div className="flex items-end">
                      <Picker
                        mode="seconds"
                        setValue={(v1, v2, v3) => editState.setEvery(v3)}
                        value={[0, 0, editState.every]}
                      />{" "}
                      <p className="ml-3 text-sm text-gray-200">hours</p>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.4 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      max until
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines the maximum time this schedule{" "}
                      <span className="font-bold">cannot</span> exceed. For a
                      given schedule of 17:00:00 with a max of 19 and a repeat
                      of 1, it will only run at 17:00:00 and 18:00:00
                    </p>
                    <div className="flex items-center">
                      <Picker
                        setValue={(v1, v2, v3) => {
                          editState.setMax(v3);
                        }}
                        value={[0, 0, editState.max]}
                        mode="dayhours"
                      />{" "}
                      <p className="ml-3 text-sm text-gray-200">{":00:00"}</p>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.5 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      Pump (optional)
                    </h3>
                    <p className="mt-1 mb-4 text-sm text-gray-200">
                      This determines on which other schedule this one is
                      dependant. Example: If a given schedule B has a dependency
                      A, the start of schedule B will also trigger the start of
                      schedule A
                    </p>
                    <div className="flex items-center">
                      <Dropdown
                        chosen={editState.dependency}
                        set={(parent) => editState.setDependency(parent)}
                        parents={editState.parents}
                      />
                    </div>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.1 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      Threshold before
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines how much earlier this schedule should than
                      the start of it's child. If this schedule is set as an
                      dependency for a schedule with a starttime of 17:00:00
                      with a threshold before of 3, this schedule will start at
                      16:59:57
                    </p>
                    <div className="flex items-end">
                      <Picker
                        setValue={(v1, v2, v3) => editState.setBefore(v3)}
                        value={[0, 0, editState.before]}
                        mode="seconds"
                      />{" "}
                      <p className="ml-3 text-sm text-gray-200">Seconds</p>
                    </div>
                  </motion.div>
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    initial={{ y: 100, opacity: 0 }}
                    transition={{ delay: 0.2 }}
                    className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
                  >
                    <h3 className="flex items-center truncate text-medium font-bold text-gray-200">
                      Threshold after
                    </h3>
                    <p className="mt-1 text-sm text-gray-200">
                      This determines how much longer this schedule should than
                      the end of it's child. If this schedule is set as an
                      dependency for a schedule with a endtime of 17:00:20 with
                      a threshold after of 5, this schedule will end at 17:00:25
                    </p>
                    <div className="flex items-end">
                      <Picker
                        setValue={(v1, v2, v3) => editState.setAfter(v3)}
                        value={[0, 0, editState.after]}
                        mode="seconds"
                      />{" "}
                      <p className="ml-3 text-sm text-gray-200">Seconds</p>
                    </div>
                  </motion.div>
                </>
              )}
              <motion.div
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 100, opacity: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
              >
                <h3 className=" flex items-center truncate text-medium font-bold text-gray-200">
                  Pin
                </h3>
                <p className="mt-1 text-sm text-gray-200">
                  This determines the pin this schedule should trigger on the
                  Raspberry Pi 4
                </p>
                <div className="flex items-end">
                  <Picker
                    setValue={(v1, v2, v3) => editState.setPin(v3)}
                    value={[0, 0, editState.pin]}
                    mode="seconds"
                  />{" "}
                  <p className="ml-3 text-sm text-gray-200">Pin number</p>
                </div>
              </motion.div>
              <motion.div
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 100, opacity: 0 }}
                transition={{ delay: 0.3 }}
                className="bg-gray-900 border-2 border-gray-700 p-5 mt-6 rounded-md"
              >
                <h3 className="mt-6 flex items-center truncate text-medium font-bold text-gray-200">
                  Raspberry PI
                </h3>
                <p className="mt-1 text-sm text-gray-200">
                  This determines which raspberry pi will be used to trigger the
                  schedule
                </p>
                <div className="flex items-end mt-3">
                  <Dropdown
                    chosen={raspBerrys.find(
                      (r) => r.id === editState.raspberry
                    )}
                    set={(parent) => editState.setRaspberry(parent.id)}
                    parents={raspBerrys}
                  />
                </div>
              </motion.div>
              <button
                onClick={() => setShowConfirm(true)}
                type="button"
                className="inline-flex items-center w-full justify-center mt-7 rounded-md border border-transparent bg-indigo-600 px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                🔥 Update
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
