import axios from "axios";

const instance = axios.create({
  baseURL: window.MODE === "LOCAL" ? "http://localhost:5546" : "",
});

export const workInstance = axios.create({
  baseURL:
    window.MODE === "LOCAL"
      ? "http://localhost:3334"
      : "http://192.168.1.133:3333",
});

instance.interceptors.request.use(
  (req) => {
    const auth = {
      username: localStorage.getItem("username"),
      password: localStorage.getItem("password"),
    };
    return { ...req, auth };
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response?.status === 403 || error.response?.status === 401) {
      // localStorage.clear();
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;

export const useInjectToken = async (client, session) => {
  client.defaults.headers.common["s_id"] = session.session.id;
  client.defaults.headers.common["user_id"] = session.session.user.id;
  client.defaults.headers.common["s_token"] = await session.session.getToken({
    skipCache: true,
  });

  return client;
};
