import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export default function Back({ text }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(-1)}
      className=" flex items-center text-xs font-light text-blue-400 py-2"
    >
      <ArrowLeftIcon className="w-3 h-3 mr-1" />
      {text}
    </div>
  );
}
