/* This example requires Tailwind CSS v2.0+ */
import { Transition } from "@headlessui/react";
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
} from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment/moment";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOverrideState, useSchedulesState } from "./Home";

export default function HomeList({ onNavigate }) {
  const schedulesState = useSchedulesState();
  const overrideState = useOverrideState();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState({});

  function listSchedules(unit) {
    if (!unit.schedules.length) {
      return null;
      //            return <div className={'flex flex-col mb-6'}>
      //                <h3 id={unit.name}
      //                    className={`text-white text-xs font-bold mb-1 font-medium`}>{unit ? unit.name : 'overig'}</h3>
      //                <div className={'w-4 h-1 bg-indigo-400 mb-3'}></div>
      //                <div className={'w-full flex items-center'}><ExclamationTriangleIcon
      //                    className={'text-orange-400 w-4 h-4 mr-2'}/><p className={'text-white text-xs w-full'}>This unit has
      //                    no schedules</p></div>
      //            </div>
    }
    return (
      <div className={"flex flex-col mb-5"} key={unit.name + unit._id}>
        <h3
          id={unit.name}
          className={`text-zinc-900 text-xs font-bold mb-1 font-medium`}
        >
          {unit ? unit.name : "overig"}
        </h3>
        <div className={"w-4 h-1 bg-indigo-400 mb-3"}></div>
        <div>
          <ul
            role="list"
            className={
              !schedulesState.unitMode
                ? "grid grid-cols-2 gap-y-8 gap-x-5 sm:grid-cols-4 lg:grid-cols-6  mb-10"
                : "grid grid-cols-2 gap-x-8 gap-y-1"
            }
          >
            <AnimatePresence mode="sync">
              {schedulesState.schedules
                .filter((sched) =>
                  unit.schedules.find((unitSched) => unitSched === sched._id)
                )
                .sort((a, b) =>
                  (b.name.match(/\d+/) !== null ? b.name.match(/\d+/) : 0) <
                  (a.name.match(/\d+/) !== null ? a.name.match(/\d+/) : 0)
                    ? 1
                    : -1
                )
                .sort((a, b) => (b.name.match(/\d+/) === null ? -1 : 1))
                .map((schedule, index) => {
                  if (schedulesState.unitMode) {
                    return (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        onClick={() => {
                          onNavigate("/edit/" + schedule._id);
                        }}
                        className="bg-zinc-100 border-2 border-zinc-100 flex flex-col px-4 py-5 text-black font-bold rounded-xl"
                      >
                        {schedule.name}
                      </motion.li>
                    );
                  }
                  let pressureBefore;
                  let pressureAfter;
                  let pressureEnabled = true;
                  let pressureBeforeColor = "bg-green-600";
                  let pressureAfterColor = "bg-green-600";
                  if (
                    (unit.name === "Unit 1" || unit.name === "test unit") &&
                    schedule.name?.toLowerCase()?.includes("cel")
                  ) {
                    pressureBefore = schedulesState.pressure?.sensor1 || 0;
                    pressureAfter = schedulesState.pressure?.sensor2 || 0;
                    if (pressureBefore > 2) {
                      pressureBeforeColor = "bg-orange-600";
                    }
                    if (pressureAfter > 2) {
                      pressureAfterColor = "bg-orange-600";
                    }
                    if (pressureBefore > 3) {
                      pressureBeforeColor = "bg-red-600";
                    }
                    if (pressureAfter > 3) {
                      pressureAfterColor = "bg-red-600";
                    }
                  } else {
                    pressureEnabled = false;
                  }
                  return (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      onHoverStart={() => setHovered({ [schedule._id]: true })}
                      onHoverEnd={() => setHovered({ [schedule._id]: false })}
                    >
                      <div
                        onClick={() => {
                          onNavigate("/edit/" + schedule._id);
                        }}
                        className={`bg-gray-900 flex flex-col px-4 py-5 rounded-xl ${
                          schedule.active
                            ? "border-2 border-green-400"
                            : "border-gray-700 border-2"
                        } ${schedule.disabled ? "opacity-50" : ""}`}
                      >
                        <div className="flex  text-slate-800">
                          {schedule.active ? (
                            <span className="flex items-center rounded-full bg-green-300 px-2 py-0.5 text-xs font-bold text-green-800">
                              <span>Running </span>
                              <ArrowPathIcon className="animate-spin w-3 h-3 ml-1" />
                            </span>
                          ) : (
                            <span className="flex items-center rounded-full bg-indigo-600 shadow-lg px-2 py-0.5 text-xs font-medium text-white">
                              {" "}
                              <span className="mr-5">Waiting </span>
                              <div className="dot-flashing mr-4"></div>
                            </span>
                          )}
                        </div>
                        <h2
                          className={`${
                            schedule.disabled ? "text-zinc-200" : "text-white"
                          } mt-3 text-xl flex items-center font-bold`}
                        >
                          {schedule.disabled ? (
                            <ExclamationTriangleIcon className="h-4 w-4 text-orange-400 mr-2" />
                          ) : null}{" "}
                          {schedule.name}{" "}
                        </h2>
                        <span className="text-xs text-zinc-200 h-3">
                          {schedule.dep ? (
                            <span className="">+ {schedule.dep.name}</span>
                          ) : (
                            <span>No dependencies</span>
                          )}
                        </span>
                        <div className="flex mt-1">
                          <p className="text-slate-200 mr-1">
                            {schedule.next_run_flat
                              ? moment(schedule.next_run_flat?.start)
                                  .utc()
                                  .format("HH:mm:ss")
                              : ""}
                          </p>
                          {" / "}
                          <p className="text-slate-200 ml-1">
                            {moment
                              .duration(
                                moment(schedule.close_utc).diff(
                                  moment(schedule.open_utc)
                                )
                              )
                              .asSeconds()}
                            <span className="font-bold">s</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex -mt-2">
                        <div
                          onClick={() => {
                            overrideState.setOverride(true);
                            overrideState.setSchedule(schedule.name);
                          }}
                          className="bg-gray-900 rounded-xl w-full mr-1 text-white border-2 border-gray-700 mt-4 flex justify-center items-center p-4"
                        >
                          <AdjustmentsHorizontalIcon
                            className="h-5 w-5 text-white "
                            aria-hidden="true"
                          />
                        </div>
                        <div
                          onClick={() => {
                            onNavigate("/schedule/" + schedule._id);
                          }}
                          className="bg-gray-900 w-full rounded-xl mr-1 text-white border-2 border-gray-700 mt-4 flex justify-center items-center p-4"
                        >
                          <CalendarDaysIcon
                            className="h-5 w-5 text-white "
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </motion.li>
                  );
                })}
            </AnimatePresence>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <header>
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="mb-4 flex items-center justify-between text-white"
        >
          <h1 className="text-xl font-bold leading-tight tracking-tight  text-white flex items-end">
            {schedulesState.unitMode ? "Units" : "Schedules"}
          </h1>
        </motion.div>
      </header>
      {schedulesState.units.map((unit) => listSchedules(unit))}
      {!schedulesState.unitMode ? (
        <>
          <header>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="mb-4"
            >
              <h1 className="text-xl font-bold leading-tight tracking-tight  text-white">
                Pumps
              </h1>
              <p className="text-gray-200 text-sm mt-2 mb-3">
                Pumps do not run on their own. Instead, they are triggered by
                running a different related schedule.
              </p>
            </motion.div>
          </header>
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 pb-4"
          >
            {schedulesState.parents.map((dep, index) => (
              <Transition.Root show={true} as={Fragment} key={index}>
                <motion.li
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: (index + 5) / 10 }}
                  key={index}
                >
                  <div
                    onClick={() => {
                      onNavigate("/edit/" + dep._id);
                    }}
                    className={`bg-gray-900 flex flex-col px-4 py-5 rounded-xl ${
                      dep.active
                        ? "border-2 border-green-400"
                        : " border-2 border-gray-700"
                    }`} // onClick={() => schedulesState.setInfo(dep)}
                  >
                    <div className="flex  text-slate-200">
                      {dep.active ? (
                        <span className="flex items-center rounded-full bg-green-300 px-2 py-0.5 text-xs font-bold text-green-800">
                          <span>Running </span>
                          <ArrowPathIcon className="animate-spin w-3 h-3 ml-1" />
                        </span>
                      ) : (
                        <span className="flex items-center rounded-full bg-indigo-600 shadow-lg px-2 py-0.5 text-xs font-medium text-white">
                          {" "}
                          <span className="mr-5">Waiting </span>
                          <div className="dot-flashing mr-4"></div>
                        </span>
                      )}
                    </div>
                    <p className="text-slate-200 mt-3">
                      Runs {dep.dependency_run_extra.before.seconds} seconds
                      earlier
                    </p>
                    <p className="text-slate-200 mt-1">
                      Runs {dep.dependency_run_extra.after.seconds} seconds
                      longer
                    </p>
                    <h2 className="text-white font-bold mt-3 text-xl">
                      {dep.name}
                    </h2>
                    <span className="flex mt-4 bg-opacity-10 w-min items-center rounded-full bg-indigo-400 px-3 py-2 text-xs font-bold text-indigo-300">
                      MORE
                    </span>
                  </div>
                  <div className="flex">
                    <div
                      onClick={() => {
                        overrideState.setOverride(true);
                        overrideState.setSchedule(dep.name);
                      }}
                      className="bg-gray-900 rounded-md w-full mr-1 text-white border-2 border-gray-700 mt-4 flex justify-center items-center p-4"
                    >
                      <AdjustmentsHorizontalIcon
                        className="h-5 w-5 text-white "
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      onClick={() => {
                        onNavigate("/history/" + dep._id);
                      }}
                      className="bg-gray-900 w-full mr-1 rounded-md text-white border-2 border-gray-700 mt-4 flex justify-center items-center p-4"
                    >
                      <CalendarDaysIcon
                        className="h-5 w-5 text-white "
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </motion.li>
              </Transition.Root>
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
}
