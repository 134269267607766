import { useClerk } from "@clerk/clerk-react";
import { useState } from "react";
import { Notifications } from "./Notification";
import Routees from "./Routes";
import { Timer } from "./Time";
import SlideOver from "./slide-over-menu";

export const CheckIfAllowed = () => {
  const [openSlide, setOpenSlide] = useState(false);
  const { user, signOut } = useClerk();
  if (
    // !!(window.ALLOWED || []).includes(user.emailAddresses?.[0].emailAddress) &&
    // !!user
    true
  ) {
    return (
      <>
        <Timer />
        <SlideOver open={openSlide} setOpen={setOpenSlide} />
        <>
          <Notifications />
          <Routees openSlide={openSlide} setOpenSlide={setOpenSlide} />
        </>
      </>
    );
  }
};
