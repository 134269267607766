/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid";
import {
  ExclamationCircleIcon,
  PlayPauseIcon,
} from "@heroicons/react/24/solid";
import moment from "moment";
import { Fragment, useState } from "react";
import { useOverrideState } from "./Home";
import { useNotifyState } from "./Notification";
import { Picker } from "./Picker";
import { socket } from "./Routes";

const Root = Transition.Root;

export default function Override() {
  //   const ts = timeState();
  const overrideState = useOverrideState();
  const ns = useNotifyState();
  const [openFor, setFor] = useState([0, 0, 0]);

  //   useEffect(() => {
  //     if (overrideState.activePins.length) {
  //       setFor((prev) => {
  //         let newHour = prev[0];
  //         let newMinute = prev[1];
  //         let newSecond = prev[2];
  //         newSecond = newSecond - 1;
  //         if (newSecond < 0) {
  //           if (newMinute > 0) {
  //             newMinute = newMinute - 1;
  //             newSecond = 59;
  //           } else if (newMinute === 0) {
  //             if (newHour > 0) {
  //               newHour = newHour - 1;
  //               newMinute = newMinute - 59;
  //               newSecond = newSecond - 59;
  //             }
  //           }
  //         }
  //         return [newHour, newMinute, newSecond];
  //       });
  //     }
  //   }, [ts.tick]);

  const override = () => {
    if (!socket) {
      ns.setNotification({
        title: `Override not working!`,
        sub: "please contact Fabio asap.",
        Icon: () => (
          <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
        ),
      });
      return;
    }
    console.log("emit", overrideState.schedule);
    console.log(openFor[0] * 3600 + openFor[1] * 60 + openFor[2]);
    const now = moment(new Date());
    socket?.emit(
      "change_override",
      overrideState.schedule,
      openFor[0] * 3600 + openFor[1] * 60 + openFor[2],
      now.year(),
      now.month(),
      now.date(),
      now.hour(),
      now.minute(),
      now.second()
    );
  };

  return (
    <Root show={overrideState.override} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={overrideState.setOverride}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden bg-white rounded-xl border-2 border-zinc-100 px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {overrideState.activePins.length ? (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <PlayIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-slate-100">
                      <PauseIcon
                        className="h-6 w-6 text-slate-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-black"
                    >
                      {overrideState.activePins.length
                        ? `Currently pumping pins ${overrideState.activePins}`
                        : "Currently not pumping manually"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Click the big blue button to start/stop pumping manually
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center flex-col items-center">
                  <Picker
                    setValue={(v1, v2, v3) => {
                      setFor([
                        v1 === -1 ? 0 : v1,
                        v2 === -1 ? 0 : v2,
                        v3 === -1 ? 0 : v3,
                      ]);
                    }}
                    value={[openFor[0], openFor[1], openFor[2]]}
                  />
                  <button
                    type="button"
                    onClick={override}
                    className="flex mt-3 border-b-8 mb-5 py-3 w-80 justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 text-base font-medium text-white shadow-sm  sm:text-sm"
                  >
                    <PlayPauseIcon
                      className=" ml-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Root>
  );
}
