import { BeakerIcon } from "@heroicons/react/20/solid";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Team", href: "#", current: false },
  { name: "Projects", href: "#", current: false },
  { name: "Calendar", href: "#", current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Logo = () => {
  return (
    <div className="flex items-center my-3 rounded-md">
      <BeakerIcon className="text-indigo-400 w-6 h-6 mr-2" />
      <p className="text-slate-200 font-bold text-xs">Scheduler</p>
    </div>
  );
};

// export const Nav = () => {
//   return (
//     <>
//       <div className="h-11"></div>
//       <Disclosure
//         as="nav"
//         className=" bg-zinc-900 fixed w-full top-0 left-0"
//         style={{ zIndex: 1000000 }}
//       >
//         {({ open }) => (
//           <>
//             <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-3">
//               <div className="flex h-16 justify-between">
//                 <div className="flex">
//                   {/* <Logo /> */}
//                   <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
//                     {navigation.map((item) => (
//                       <a
//                         key={item.name}
//                         href={item.href}
//                         className={classNames(
//                           item.current
//                             ? "border-indigo-500 text-white"
//                             : "border-transparent text-gray-500 hover:border-indigo-600 hover:white",
//                           "inline-flex items-center px-1 py-4 border-b-2 text-sm font-medium"
//                         )}
//                         aria-current={item.current ? "page" : undefined}
//                       >
//                         {item.name}
//                       </a>
//                     ))}
//                   </div>
//                 </div>
//                 <div className="hidden sm:ml-6 sm:flex sm:items-center">
//                   <button
//                     type="button"
//                     className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//                   >
//                     <span className="sr-only">View notifications</span>
//                     <BellIcon className="h-6 w-6" aria-hidden="true" />
//                   </button>
//                 </div>
//                 <div className="-mr-2 flex items-center sm:hidden">
//                   {/* Mobile menu button */}
//                   <Disclosure.Button className="inline-flex items-center justify-center rounded-md  p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
//                     <span className="sr-only">Open main menu</span>
//                     {open ? (
//                       <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
//                     ) : (
//                       <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
//                     )}
//                   </Disclosure.Button>
//                 </div>
//               </div>
//             </div>

//             <Disclosure.Panel className="sm:hidden">
//               <div className="space-y-1 pt-2 pb-3">
//                 {navigation.map((item) => (
//                   <Disclosure.Button
//                     key={item.name}
//                     as="a"
//                     href={item.href}
//                     className={classNames(
//                       item.current
//                         ? "bg-zinc-800 border-indigo-500 text-slate-200"
//                         : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
//                       "block pl-3 pr-4 py-5 border-l-4 text-base font-medium"
//                     )}
//                     aria-current={item.current ? "page" : undefined}
//                   >
//                     {item.name}
//                   </Disclosure.Button>
//                 ))}
//               </div>
//             </Disclosure.Panel>
//           </>
//         )}
//       </Disclosure>
//     </>
//   );
// };

export const Nav = () => <div className="h-12 bg-gray-900"></div>;
