/* This example requires Tailwind CSS v2.0+ */

import { useSession } from "@clerk/clerk-react";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Back from "./Back";
import { useNotifyState } from "./Notification";
import client, { useInjectToken } from "./client";
export default function History() {
  const ns = useNotifyState();
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const { _id } = useParams();
  const [entry, setEntry] = useState([]);

  const fetchall = async () => {
    const now = moment(new Date());
    (await c)
      .get(
        `/history/${now.year()}/${
          now.month() + 1
        }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}/${_id}/${24}`
      )
      .then(({ data }) => {
        setEntry(data);
      });
  };

  useEffect(() => {
    fetchall();
  }, []);

  return (
    <>
      <div className="min-h-full">
        <div className="py-5">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Back text={"Full schedule"} />
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-white flex-col flex">
                  <span>Real history</span>
                </h1>
              </div>
              <p className="mt-1 text-sm text-gray-200">
                Here you can view the full history. history is real.
              </p>
            </div>
          </motion.div>
          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mt-6 flow-root">
              <ul role="list" className="-my-5 divide-y divide-zinc-800 mt-7">
                {entry
                  .sort((a, b) => new Date(b.time) - new Date(a.time))
                  .map((currentEntry, i) => {
                    const start = new Date(currentEntry.time);
                    const normalStartTime = moment(new Date());
                    normalStartTime.set("h", start.getUTCHours());
                    normalStartTime.set("m", start.getUTCMinutes());
                    normalStartTime.set("s", start.getUTCSeconds());
                    normalStartTime.set("month", start.getUTCMonth());
                    normalStartTime.set("date", start.getUTCDate());

                    const stop = new Date(currentEntry.time);
                    const normalSopTime = moment(new Date());
                    normalSopTime.set("h", stop.getUTCHours());
                    normalSopTime.set("m", stop.getUTCMinutes());
                    normalSopTime.set("s", stop.getUTCSeconds());
                    normalSopTime.set("month", stop.getUTCMonth());
                    normalSopTime.set("date", stop.getUTCDate());
                    const disabled = false;

                    const nextMoreThanSecond =
                      Math.abs(
                        new Date(currentEntry?.time).getSeconds() -
                          new Date(entry?.[i + 1]?.time).getSeconds()
                      ) > 1;
                    let previousMoreThanSecond;
                    if (!entry[i - 1]) {
                      previousMoreThanSecond = true;
                    } else {
                      previousMoreThanSecond =
                        Math.abs(
                          new Date(currentEntry?.time).getSeconds() -
                            new Date(entry?.[i - 1]?.time).getSeconds()
                        ) > 1;
                    }
                    return (
                      <>
                        {previousMoreThanSecond ? (
                          <motion.li
                            initial={{ x: -10, opacity: 0 }}
                            animate={{ x: 0, opacity: disabled ? 0.5 : 1 }}
                            transition={{ delay: 0.2 }}
                            key={currentEntry.start}
                          >
                            <div className="flex items-center">
                              <div className="min-w-0 flex-1 flex justify-end">
                                <p className="truncate text-sm font-medium text-black"></p>
                              </div>
                              <div></div>
                            </div>
                          </motion.li>
                        ) : null}
                        <motion.li
                          initial={{ x: -10, opacity: 0 }}
                          animate={{ x: 0, opacity: disabled ? 0.5 : 1 }}
                          transition={{ delay: 0.2 }}
                          key={currentEntry.start}
                        >
                          <div className="flex items-center justify-between">
                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-black">
                                {normalStartTime.format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}{" "}
                                -- {currentEntry.schedule_name} / Pin:{" "}
                                {currentEntry.schedule_pin}
                              </p>
                            </div>
                            <div>
                              <p className="truncate text-sm font-medium text-black">
                                {currentEntry.tags}
                              </p>
                            </div>
                            <div>
                              <p>--</p>
                            </div>
                            <div>
                              <p className="truncate text-sm font-medium text-black">
                                {currentEntry.is_override
                                  ? "is override!"
                                  : null}
                              </p>
                            </div>
                            <div>
                              <p>--</p>
                            </div>
                            <div>
                              <p className="truncate text-sm font-medium text-black">
                                {currentEntry.switched_to === 1
                                  ? "to: ON"
                                  : "to: OFF"}
                              </p>
                            </div>
                          </div>
                        </motion.li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
