import { useSession, useUser } from "@clerk/clerk-react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import create from "zustand";
import HomeList from "./HomeList";
import Info from "./Info";
import Nextup from "./Nextup";
import { useNotifyState } from "./Notification";
import Override from "./Override";
import { socket } from "./Routes";
import { timeState } from "./Time";
import client, { useInjectToken } from "./client";

export const useSchedulesState = create((set) => ({
  schedules: [],
  pressure: {},
  unitMode: false,
  setUnitMode: (unitMode) => set({ unitMode }),
  actives: [],
  parents: [],
  units: [],
  setUnits: (units) => set({ units }),
  setParents: (parents) => set({ parents }),
  setActives: (actives) => set({ actives }),
  setSchedules: (schedules) => set({ schedules }),
  test: [],
  setTest: (s) => set((p) => ({ test: [...p.test, s] })),
  info: null,
  setInfo: (info) => set({ info }),
  selected: "schedules",
  setSelected: (selected) => set({ selected }),
  setPressure: (pressure) => set({ pressure }),
}));

export const useOverrideState = create((set) => ({
  override: false,
  previousState: false,
  schedule: "",
  activePins: [],
  setActivePins: (pins) => set({ activePins: pins }),
  setSchedule: (schedule) => set({ schedule }),
  setOverride: (state) =>
    set((prev) => ({ override: state, previousState: prev.override })),
}));
export const useHomeState = create((set) => ({
  render: false,
  setRender: (render) => set({ render }),
}));
export default function Home() {
  const schedulesState = useSchedulesState();
  const overrideState = useOverrideState();
  const ts = timeState();
  const homeState = useHomeState();
  const ns = useNotifyState();
  const [opened, setOpened] = useState([]);
  const [latestLength, setLatestLength] = useState(0);
  const nagivate = useNavigate();
  const [connecting, setConnecting] = useState(false);
  const u = useUser();
  const isAdmin = u.user.organizationMemberships[0].role === "admin";

  const ww = useSession();
  const c = useInjectToken(client, ww);
  useEffect(() => {
    homeState.setRender(true);
  }, []);
  const [active, setActive] = useState(false);
  useEffect(() => {
    fetchState();
    fetchUnits();
    return () => null;
  }, []);
  const fetchState = async () => {
    const now = moment(new Date());
    const scheds = await (
      await c
    ).get(
      `/schedules/${now.year()}/${
        now.month() + 1
      }/${now.date()}/${now.hour()}/${now.minute()}/${now.second()}`
    );
    schedulesState.setSchedules(scheds.data);
    const parents = await (await c).get(`/parents`);
    schedulesState.setParents(parents.data);
  };
  const fetchUnits = async () => {
    const units = await (await c).get(`/unit`);
    schedulesState.setUnits(units.data);
  };

  useEffect(() => {
    (async () => {
      (await c).get("/shifts/active").then(({ data }) => {
        setActive(data);
      });
    })();
  }, []);

  useEffect(() => {
    socket?.emit("get_actives");
    socket?.emit("request_pressure");
  }, [ts.tick]);
  useEffect(() => {
    if (!socket || socket.connected || connecting) {
      return;
    }
    setConnecting(true);
    socket?.emit("request_override_state");
    socket?.on("override_state_open", (pins) => {
      ns.setNotification({
        title: `${pins} opened`,
        sub: "",
        Icon: () => <CheckCircleIcon className="text-green-400 h-6 w-6" />,
      });
      overrideState.setActivePins(pins);
    });
    socket?.on("override_state_close", (pins) => {
      overrideState.setActivePins([]);
    });
    socket?.on("pressure", (latest) => {
      schedulesState.setPressure(latest);
    });
    socket?.on("actives", (schedules) => {
      fetchState();
      schedulesState.setActives(schedules);
      if (schedules.length === latestLength) {
        return;
      }
      schedulesState.setActives(schedules);
      if (!schedules.length) {
        setOpened([]);
      }
      if (schedules.length) {
        let addToNotify = "";
        schedules.forEach((s) => {
          // schedule is already registered as opened
          if (opened.find((o) => o === s)) {
          } else {
            if (!addToNotify.length) {
              addToNotify = s;
            } else {
              addToNotify = addToNotify + ", " + s;
            }
            setOpened((p) => [...p, s]);
          }
        });
        // if (addToNotify.length) {
        //   ns.setNotification({
        //     title: `${addToNotify} opened`,
        //     sub: "They will close on themselves",
        //     Icon: () => <CheckCircleIcon className="text-green-400 h-6 w-6" />,
        //   });
        // }
      }
      setLatestLength(schedules.length);
    });
    console.log("getting here");
  }, [socket]);

  if (!homeState.render) {
    return <></>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="overflow-y-scroll pb-10"
    >
      {/* {!isAdmin && (
        <>
          {!active ? (
            <div
              className="flex items-center px-4 py-3 bg-gray-100 m-6 rounded-md justify-between"
              // style={{ borderWidth: "1px" }}
            >
              <div className="flex items-center">
                <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-orange-600" />
                <p className="text-gray-700">
                  Hey {u.user.firstName}, you are currently not checked in.
                </p>
              </div>
              <button
                onClick={() => nagivate("/shifts")}
                className="bg-orange-600 text-white p-3 rounded-lg text-sm"
              >
                Check in
              </button>
            </div>
          ) : (
            <div className="flex items-center px-4 py-3 bg-gray-100 m-6 rounded-md justify-between">
              <div className="flex items-center">
                <CheckBadgeIcon className="h-6 w-6 mr-3 text-indigo-500" />
                <p className="text-gray-700">
                  Hey {u.user.firstName}, you are currently checked in.
                </p>
              </div>
              <button
                onClick={() => nagivate("/shifts")}
                className="bg-indigo-500 text-white p-3 rounded-lg text-sm"
              >
                Check out
              </button>
            </div>
          )}
        </>
      )} */}
      <Override />
      <Info
        open={schedulesState.info != null}
        setOpen={() => {
          schedulesState.setInfo();
        }}
      />
      <div className="py-2">
        <main>
          <div className="mx-5 mt-6 max-w-7xl sm:px-6 lg:px-8">
            {!schedulesState.unitMode ? (
              <Nextup
                onNavigate={(path) => {
                  homeState.setRender(false);
                  setTimeout(() => {
                    nagivate(path);
                  }, 100);
                }}
              />
            ) : null}
            <HomeList
              onNavigate={(path) => {
                homeState.setRender(false);
                setTimeout(() => {
                  nagivate(path);
                }, 100);
              }}
            />
          </div>
        </main>
      </div>
      {/*<Units />*/}
    </motion.div>
  );
}
