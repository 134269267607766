import { useClerk, useUser } from "@clerk/clerk-react";
import { Dialog, Transition } from "@headlessui/react";
import { CalendarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftOnRectangleIcon,
  HomeIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

export default function SlideOver({ open, setOpen }) {
  const f = useUser();
  const isAdmin = f.user.organizationMemberships[0]?.role === "admin";
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md pt-3">
                  <div className="flex h-full flex-col overflow-y-scroll bg-gray-900 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <div className="mt-5 relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-gray-900 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                          <div className="flex-shrink-0">
                            {/* <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" /> */}
                            <div className="h-10 w-10 rounded-full bg-green-400 flex justify-center items-center text-white">
                              {f.user?.firstName?.charAt(0)}
                            </div>
                          </div>
                          <div className="min-w-0 flex-1">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-200">
                              {f.user?.firstName}
                            </p>
                            <p className="truncate text-sm text-gray-200">
                              {" "}
                              {isAdmin ? "Manager" : "Employee"}
                            </p>
                          </div>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-gray-900 text-gray-200 hover:text-gray-200"
                            onClick={() => setOpen(false)}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <Navigation setOpen={setOpen} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const navigation = [
  { name: "Home", href: "/home", current: false, icon: HomeIcon },
  { name: "Create", href: "/create", current: false, icon: PencilIcon },
  { name: "Scheduler", href: "/scheduler", current: false, icon: CalendarIcon },
  { name: "Schifts", href: "/shifts", current: false, icon: PencilSquareIcon },
  {
    name: "Logout",
    href: "#",
    icon: ArrowLeftOnRectangleIcon,
    current: false,
    onClick: (signOut) => {
      signOut();
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Navigation({ setOpen }) {
  const { signOut } = useClerk();
  const location = useLocation();

  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul role="list" className="-mx-2 mt-2 space-y-1">
        {navigation.map((item) => (
          <li>
            <Link
              key={item.name}
              onClick={
                item.onClick
                  ? () => {
                      item.onClick(signOut);
                      setOpen(false);
                    }
                  : () => setOpen(false)
              }
              to={item.href}
              className={classNames(
                location.pathname === item.href
                  ? "bg-gray-800 text-white"
                  : "text-gray-200 hover:text-white hover:bg-gray-800",
                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
              )}
            >
              <item.icon
                className={classNames(
                  location.pathname === item.href
                    ? "text-white"
                    : "text-gray-200 group-hover:text-white",
                  "h-6 w-6 shrink-0"
                )}
                aria-hidden="true"
              />
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
