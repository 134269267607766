import { useEffect, useState } from "react";
import create from "zustand";

export const timeState = create((set, get) => {
  return {
    tick: true,
    setTick: () => set({ tick: !get().tick }),
  };
});

export const Timer = () => {
  const [time, setTime] = useState(new Date());
  const ts = timeState();

  useEffect(() => {
    setInterval(() => {
      setTime((prev) => {
        if (prev.getSeconds() !== new Date().getSeconds()) {
          ts.setTick();
          return new Date();
        }
        return prev;
      });
    }, 1000);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-5 bg-green-400 text-xs flex justify-between p-2 text-white font-semibold`}
      style={{ zIndex: 1000000000000 }}
    >
      <div className={`h-full flex justify-center items-center`}>
        {window.VERSION}
      </div>
      <div className={`h-full flex justify-center items-center`}>
        {time.getHours()}:{time.getMinutes()}:{time.getSeconds()}
      </div>
    </div>
  );
};
