import { Listbox, Transition } from "@headlessui/react";
import {
  BarsArrowUpIcon,
  CalendarDaysIcon,
  CheckIcon,
  FolderOpenIcon,
  HomeIcon,
} from "@heroicons/react/20/solid";
import { Bars4Icon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSchedulesState } from "./Home";
import { Units } from "./Units";

export const BottomNavigation = ({ openSlide, setOpenSlide }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const st = useSchedulesState();

  const [showUnits, setShowUnits] = useState(false);

  function scrollBack(offsetPosition) {
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
    setShowUnits(false);
  }

  return (
    <>
      {showUnits ? (
        <Units cb={scrollBack} show={true} setShow={setShowUnits} />
      ) : null}
      <div className="fixed inset-x-2 bottom-2 rounded-xl z-10 bg-gray-800  shadow py-1">
        <section
          id="bottom-navigation"
          className="inset-x-0 bottom-0 z-10 shadow py-1"
        >
          <div id="tabs" className="flex justify-between">
            <Link
              to={"/"}
              className={`w-full focus:text-teal-500 ${
                location.pathname === "/" ? "bg-green-400" : ""
              } transition  mx-2 rounded-lg hover:text-teal-500 justify-center flex flex-col items-center pt-2 pb-1`}
            >
              <HomeIcon
                className={`${
                  location.pathname === "/" ? "text-white" : "text-white"
                } h-6 w-6 mb-1`}
              />
              <span
                className={`tab tab-home block text-xs ${
                  location.pathname === "/" ? "text-white" : "text-white"
                } font-semibold`}
              >
                Home
              </span>
            </Link>
            {st.unitMode ? (
              <Link
                to={"/units"}
                className={`w-full focus:text-teal-500 hover:text-teal-500 mx-2 justify-center ${
                  location.pathname === "/units" ? "bg-indigo-500" : ""
                }  rounded-lg flex flex-col items-center pt-2 pb-1`}
              >
                <FolderOpenIcon
                  className={`${
                    location.pathname === "/create"
                      ? "text-white"
                      : "text-white"
                  } h-6 w-6 mb-1`}
                />
                <span
                  className={`tab tab-home block text-xs ${
                    location.pathname === "/create"
                      ? "text-white"
                      : "text-white"
                  } font-semibold`}
                >
                  Create unit
                </span>
              </Link>
            ) : (
              <Link
                to={"/create"}
                className={`w-full focus:text-teal-500 hover:text-teal-500 mx-2 justify-center ${
                  location.pathname === "/create" ? "bg-indigo-500" : ""
                }  rounded-lg flex flex-col items-center pt-2 pb-1`}
              >
                <CalendarDaysIcon
                  className={`${
                    location.pathname === "/create"
                      ? "text-white"
                      : "text-white"
                  } h-6 w-6 mb-1`}
                />
                <span
                  className={`tab tab-home block text-xs ${
                    location.pathname === "/create"
                      ? "text-white"
                      : "text-white"
                  } font-semibold`}
                >
                  Create
                </span>
              </Link>
            )}
            {/*<UnitSelector />*/}
            <button
              onClick={() => {
                if (location.pathname === "/home") {
                  st.setUnitMode(!st.unitMode);
                } else {
                  st.setUnitMode(true);
                  navigate("/");
                }
              }}
              className={`w-full focus:text-teal-500 hover:text-teal-500 mx-2 justify-center flex ${
                st.unitMode === true ? "bg-gray-800" : "text-white"
              }  rounded-lg flex-col items-center pt-2 pb-1`}
            >
              <BarsArrowUpIcon
                className={`${
                  !st.unitMode ? "text-white" : "text-white"
                } h-6 w-6 mb-1`}
              />
              <span
                className={`tab tab-home block text-xs ${
                  !st.unitMode ? "text-white" : "text-white"
                } font-semibold`}
              >
                Unit mode
              </span>
            </button>
            <button
              onClick={() => {
                setOpenSlide(true);
              }}
              className={`w-full focus:text-teal-500 hover:text-teal-500 mx-2 justify-center flex ${"text-black"}  rounded-lg flex-col items-center pt-2 pb-1`}
            >
              <Bars4Icon className={`${"text-white"} h-6 w-6 mb-1`} />
              <span
                className={`tab tab-home block text-xs ${"text-white"} font-semibold`}
              >
                More
              </span>
            </button>
          </div>
        </section>
      </div>
      <div className={"h-24 bg-gray-900"}></div>
    </>
  );
};

const publishingOptions = [
  {
    title: "Published",
    description: "This job posting can be viewed by anyone who has the link.",
    current: true,
  },
  {
    title: "Draft",
    description: "This job posting will no longer be publicly accessible.",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function UnitSelector() {
  const location = useLocation();
  const [selected, setSelected] = useState(publishingOptions[0]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative w-full transition  rounded-lg hover:text-teal-500 justify-center flex flex-col items-center px-2">
            <div className="inline-flex divide-x divide-indigo-600 rounded-md shadow-sm">
              <div className="inline-flex divide-x divide-indigo-600 rounded-md shadow-sm">
                <Listbox.Button
                  className={`w-full focus:text-teal-500 hover:text-teal-500 m-2 justify-center flex ${
                    location.pathname === "/home" ? "" : "disabled opacity-20"
                  }  rounded-lg flex-col items-center pt-2 pb-1`}
                >
                  <BarsArrowUpIcon className={"text-white h-6 w-6 mb-1"} />
                  <span className="tab tab-home block text-xs text-white font-semibold">
                    Units
                  </span>
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-bottom-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-500" : "text-gray-900",
                        "cursor-default select-none p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? "text-white" : "text-indigo-500"
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? "text-indigo-200" : "text-gray-500",
                            "mt-2"
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
