export const Picker = ({ mode, setValue, value }) => {
  if (mode === "dayhours") {
    return (
      <div className="mt-2 p-3 flex justify-center items-center bg-gray-800 rounded-lg shadow">
        <select
          name="ampm"
          onChange={(e) => {
            setValue(-1, -1, e.target.value);
          }}
          value={value[2]}
          style={{ padding: "10px" }}
          className="bg-transparent text-xl text-white appearance-none outline-none"
        >
          {[...new Array(48)].map((n, i) => {
            return (
              <option value={i}>
                {String(i > 23 ? i - 24 : i).padStart(2, "0")}{" "}
                {i > 23 ? "Next day" : "Today"}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
  if (mode === "hours") {
    return (
      <div className="mt-2 p-3 w-12 flex justify-center items-center bg-gray-800 rounded-lg shadow">
        <select
          name="hours"
          onChange={(e) => setValue(e.target.value, -1, -1)}
          style={{ padding: "10px" }}
          value={value[0]}
          className="bg-transparent text-xl text-white appearance-none outline-none "
        >
          {[...new Array(24)].map((n, i) => {
            return <option value={i}>{String(i).padStart(2, "0")}</option>;
          })}
        </select>
      </div>
    );
  }
  if (mode === "seconds") {
    return (
      <div className="mt-2 p-3 w-12 flex justify-center items-center bg-gray-800 rounded-lg shadow">
        <select
          name="ampm"
          onChange={(e) => setValue(-1, -1, e.target.value)}
          value={value[2]}
          style={{ padding: "10px" }}
          className="bg-transparent  text-xl text-white appearance-none outline-none"
        >
          {[...new Array(60)].map((n, i) => {
            return <option value={i}>{String(i).padStart(2, "0")}</option>;
          })}
        </select>
      </div>
    );
  }
  if (mode === "minuteseconds") {
    return (
      <div className="mt-2  p-3 flex justify-center items-center bg-gray-800 rounded-lg shadow">
        <div className="flex items-center">
          <select
            name="minutes"
            onChange={(e) => setValue(-1, e.target.value, -1)}
            value={value[1]}
            style={{ padding: "10px" }}
            className="bg-transparent text-xl appearance-none text-white outline-none mr-4"
          >
            {[...new Array(60)].map((n, i) => {
              return <option value={i}>{String(i).padStart(2, "0")}</option>;
            })}
          </select>
          <span className="text-xl mr-3 text-white">:</span>
          <select
            name="ampm"
            onChange={(e) => setValue(-1, -1, e.target.value)}
            style={{ padding: "10px" }}
            value={value[2]}
            className="bg-transparent text-xl appearance-none text-white outline-none"
          >
            {[...new Array(60)].map((n, i) => {
              return <option value={i}>{String(i).padStart(2, "0")}</option>;
            })}
          </select>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-2  p-3 flex justify-center items-center bg-gray-800 rounded-lg shadow">
      <div className="flex items-center">
        <select
          name="hours"
          onChange={(e) => setValue(e.target.value, -1, -1)}
          style={{ padding: "10px" }}
          value={value[0]}
          className="bg-transparent text-xl appearance-none outline-none text-white mr-4"
        >
          {[...new Array(24)].map((n, i) => {
            return <option value={i}>{String(i).padStart(2, "0")}</option>;
          })}
        </select>
        <span className="text-xl mr-3 text-white">:</span>
        <select
          name="minutes"
          onChange={(e) => setValue(-1, e.target.value, -1)}
          style={{ padding: "10px" }}
          value={value[1]}
          className="bg-transparent text-xl appearance-none text-white outline-none mr-4"
        >
          {[...new Array(60)].map((n, i) => {
            return <option value={i}>{String(i).padStart(2, "0")}</option>;
          })}
        </select>
        <span className="text-xl mr-3 text-white">:</span>
        <select
          name="ampm"
          onChange={(e) => setValue(-1, -1, e.target.value)}
          style={{ padding: "10px" }}
          value={value[2]}
          className="bg-transparent text-xl appearance-none text-white outline-none"
        >
          {[...new Array(60)].map((n, i) => {
            return <option value={i}>{String(i).padStart(2, "0")}</option>;
          })}
        </select>
      </div>
    </div>
  );
};
