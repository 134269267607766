import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./Dots.css";

import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import { CheckIfAllowed } from "./CheckIfAllowed";

if (!window.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = window.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <>
      <ClerkProvider publishableKey={window.REACT_APP_CLERK_PUBLISHABLE_KEY}>
        <Router>
          <SignedIn>
            <CheckIfAllowed />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </Router>
      </ClerkProvider>
    </>
  );
}

export default App;
