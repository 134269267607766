/* This example requires Tailwind CSS v2.0+ */

import { useSession, useUser } from "@clerk/clerk-react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowDownRightIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import client, { useInjectToken } from "./client";

import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { BarChart } from "./BarChart";
import { useNotifyState } from "./Notification";
import Timeline from "./Timeline";
dayjs.extend(utc);
dayjs.extend(tz);

const weekdayMapping = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const Loader = () => {
  return (
    <div
      role="status"
      className="w-screen h-72 flex justify-center items-center"
    >
      <svg
        aria-hidden="true"
        class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-200 fill-indigo-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default function Shifts() {
  const f = useUser();
  const [loading, setLoading] = useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);

  const isAdmin = f.user.organizationMemberships[0]?.role === "admin";

  const [entry, setEntry] = useState({});
  // subtract a week if its sunday
  const [week, setWeek] = useState(
    moment().day() === 0 ? moment().week() - 1 : moment().week()
  );
  const [year, setYear] = useState(moment().year());
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const fetchall = async (w) => {
    setLoading(true);
    const searchParams = new URLSearchParams({ year: year });
    (await c)
      .get(`shifts/my/${w || week}?${searchParams.toString()}`)
      .then(({ data }) => {
        setEntry(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    fetchall(week);
  }, [week, year]);

  const hasCurrentlyActiveOne = () => {
    return Object.keys(entry).some((key) => {
      const { shifts } = entry[key];
      return shifts.some((shift) => shift.active);
    });
  };

  const [tasks, setTasks] = useState({});

  const fetchShiftsByDay = async (w) => {
    const searchParams = new URLSearchParams({ year: year });
    (await c)
      .get(`tasks/${w || week}/by-day?${searchParams.toString()}`)
      .then(({ data }) => {
        setTasks(data);
      });
  };

  useEffect(() => {
    fetchShiftsByDay(week);
  }, [week, year]);

  useEffect(() => {
    fetchall();
  }, []);

  const createShift = async () => {
    setLoading(true);
    setActionsLoading(true);
    await (await c).post(`shifts/start`).catch((e) => {
      setLoading(false);
      setActionsLoading(false);
    });
    setLoading(false);
    setActionsLoading(false);
    await fetchall();
  };

  const stop = async () => {
    setLoading(true);
    setActionsLoading(true);
    await (await c).post(`shifts/stop`).catch(() => {
      setLoading(false);
      setActionsLoading(false);
    });
    setLoading(false);
    setActionsLoading(false);
    await fetchall();
  };

  const disable = async () => {
    setLoading(true);
    await (await c).post(`shifts/pause`).catch(() => {
      setLoading(false);
      setActionsLoading(false);
    });
    setActionsLoading(false);
    setLoading(false);
    await fetchall();
  };

  const enable = async () => {
    setLoading(true);
    await (await c).post(`shifts/resume`).catch(() => {
      setLoading(false);
      setActionsLoading(false);
    });
    setActionsLoading(false);
    setLoading(false);
    await fetchall();
  };

  const hasUncompletedTasksForToday = () => {
    const today = dayjs().tz("Europe/Amsterdam").startOf("day").format("dddd");
    return (
      tasks[today] &&
      tasks[today].some((t) => {
        return (
          !t.completedByAssignee.some((c) => c.id === f.user.id) &&
          t.assignedUser.some((a) => a.id === f.user.id)
        );
      })
    );
  };

  const [openDetails, setOpenDetails] = useState(false);
  const [details, setDetails] = useState({});
  const [forgotClockOut, setForgotClockOut] = useState();

  return (
    <div className="bg-gray-900 text-white">
      <Details open={openDetails} setOpen={setOpenDetails} data={details} />
      <ClockOut
        open={forgotClockOut}
        setOpen={() => setForgotClockOut()}
        data={forgotClockOut}
      />
      <div className="min-h-full">
        <div className="py-5">
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between mb-4">
                <h1 className="text-3xl font-bold leading-tight tracking-tight  flex-col flex">
                  {isAdmin ? <span>All shifts</span> : <span>My shifts</span>}
                </h1>
              </div>
              <p className="mt-1 text-sm text-gray-100">
                Here you can view the shifts of all workers.
              </p>
              {isAdmin && (
                <div className=" max-w-xs">
                  <label
                    htmlFor="location"
                    className="block mt-4 text-sm font-medium leading-6 text-white"
                  >
                    Weeknumber{" "}
                    <span className="text-gray-200">
                      week of{" "}
                      {moment().week(week).year(year).format("DD/MM/YYYY")}
                    </span>
                  </label>
                  <div className="flex gap-x-2">
                    <select
                      id="location"
                      name="location"
                      className="mt-2 block w-full bg-slate-900 rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-700 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={week}
                      onChange={(e) => {
                        setWeek(e.target.value);
                      }}
                    >
                      {Array.from(Array(52).keys()).map((i) => {
                        i = i + 1;
                        return (
                          <option key={i} value={i}>
                            Week {i}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="location"
                      name="location"
                      className="mt-2 block w-full bg-slate-900 rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-700 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={year}
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    >
                      {[2023, 2024].map((i) => {
                        return (
                          <option key={i} value={i}>
                            year {i}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </motion.div>
          {!loading ? (
            <>
              {isAdmin ? (
                <TasksManager week={week} year={year} />
              ) : // <NormalTaskView
              //   tasks={tasks}
              //   week={week}
              //   setTasks={setTasks}
              //   hasCurrentlyActiveOne={hasCurrentlyActiveOne()}
              // />
              null}
              {/* {Object.keys(entry).length === 0 && !loading && (
                <div className="w-screen h-72 font-bold flex justify-center items-center">
                  No data
                </div>
              )} */}
            </>
          ) : null}

          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            {!isAdmin && (
              <Timeline
                year={year}
                startStopLoading={actionsLoading}
                week={week}
                weekly={entry[f.user.id]}
                enable={enable}
                disable={disable}
                start={createShift}
                stop={stop}
                hasCurrentlyActiveOne={hasCurrentlyActiveOne()}
                tasks={tasks}
                setTasks={setTasks}
              />
            )}
            <div className="mt-6 flow-root">
              <ul role="list" className="">
                {Object.keys(entry).map((key, i) => {
                  const { shifts, user } = entry[key];

                  const todayIndex =
                    moment().day() === 0 ? 6 : moment().day() - 1;

                  const forgotToclockOut =
                    entry[key]?.summedWeek?.[todayIndex]?.forgotToClockOut ||
                    entry[key]?.summedWeek?.[todayIndex - 1]?.forgotToClockOut;

                  const s = shifts.map((shift, i) => {
                    const d = moment.duration(
                      shift.totalShiftTime,
                      "milliseconds"
                    );
                    const hoursShift = Math.floor(d.asHours());
                    const minsShift =
                      Math.floor(d.asMinutes()) - hoursShift * 60;
                    const q = moment.duration(
                      shift.totalPauseTime,
                      "milliseconds"
                    );
                    const hoursShiftPause = Math.floor(q.asHours());
                    const minsShiftPause =
                      Math.floor(q.asMinutes()) - hoursShiftPause * 60;

                    const p = shift.pauses.map((p, i) => {
                      return (
                        <div className="flex items-center ml-5">
                          <ArrowDownRightIcon className="text-orange-400 w-5 h-5" />
                          <motion.li
                            initial={{ x: -10, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.2 + i / 20 }}
                            key={i}
                            className={`py-2 px-4 border-2 mt-4 border-zinc-100 
                            ml-1
                           ${
                             shift.currentlyPaused
                               ? "bg-orange-100"
                               : "bg-zinc-100"
                           }`}
                          >
                            <div className="flex items-center space-x-4">
                              <div className="min-w-0 flex-1">
                                <p className="truncate text-sm font-medium text-black">
                                  Start break:{" "}
                                  {moment(p.startPause).format(
                                    "DD/MM/YYYY - HH:mm:ss"
                                  )}{" "}
                                </p>
                                <p className="truncate text-sm text-gray-500">
                                  Stop break:{" "}
                                  {!shift.currentlyPaused
                                    ? moment(p.endPause).format(
                                        "DD/MM/YYYY - HH:mm:ss"
                                      )
                                    : "Currently breaking"}
                                </p>
                              </div>
                            </div>
                          </motion.li>
                        </div>
                      );
                    });

                    return (
                      <>
                        <motion.li
                          initial={{ x: -10, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ delay: 0.2 + i / 20 }}
                          key={i}
                          className={`py-2 px-4 border-2 mt-4 border-zinc-100 
                          ${
                            shift.currentlyPaused
                              ? "bg-orange-100"
                              : shift.active
                              ? "bg-indigo-100"
                              : "bg-zinc-100"
                          }`}
                        >
                          <div className="flex items-center space-x-4">
                            {shift.currentlyPaused ? (
                              <ExclamationTriangleIcon className="text-orange-400 w-5 h-5" />
                            ) : !shift.active ? (
                              <CheckBadgeIcon className="text-green-400 w-5 h-5" />
                            ) : (
                              <ClockIcon className="text-blue-400 w-5 h-5" />
                            )}

                            <div className="min-w-0 flex-1">
                              <p className="truncate text-sm font-medium text-black">
                                Start:{" "}
                                {moment(shift.startShift).format(
                                  "DD/MM/YYYY - HH:mm:ss"
                                )}{" "}
                              </p>
                              <p className="truncate text-sm text-gray-500">
                                Stop:{" "}
                                {shift.endShift
                                  ? moment(shift.endShift).format(
                                      "DD/MM/YYYY - HH:mm:ss"
                                    )
                                  : "Currently running"}
                              </p>
                              {!shift.active ? (
                                <>
                                  <p className="truncate text-sm font-medium text-black">
                                    Total worked time: {"\n"}
                                  </p>
                                  <p className="truncate text-sm font-medium text-black">
                                    {hoursShift} hours and {minsShift} {"\n"}
                                    minutes
                                  </p>
                                  <p className="truncate text-sm font-medium text-black">
                                    Total paused time: {"\n"}
                                  </p>
                                  <p className="truncate text-sm font-medium text-black">
                                    {hoursShiftPause} hours and {minsShiftPause}{" "}
                                    minutes
                                  </p>
                                </>
                              ) : null}
                            </div>
                            <div>
                              {shift.active ? (
                                <>
                                  <button
                                    onClick={() => {
                                      if (shift.currentlyPaused) {
                                        enable();
                                      } else {
                                        disable();
                                      }
                                    }}
                                    className="inline-flex items-center rounded-full  bg-zinc-600 px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm "
                                  >
                                    {shift.currentlyPaused
                                      ? "Resume shift"
                                      : "Pause shift"}
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </motion.li>
                        {p}
                      </>
                    );
                  });

                  return (
                    <div className="mt-5 mb-20 font-bold">
                      <span>{isAdmin ? user.data?.firstName : ""}</span>
                      {isAdmin ? (
                        <button
                          onClick={() => {
                            setDetails(s);
                            setOpenDetails(true);
                          }}
                          className="inline-flex ml-2 items-center rounded-full  bg-zinc-600 px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm "
                        >
                          View more
                        </button>
                      ) : null}
                      {isAdmin && forgotToclockOut ? (
                        <p
                          // onClick={() => {
                          //   setForgotClockOut(forgotToClockOutAmount);
                          // }}
                          className="inline-flex ml-2 items-center rounded-full  bg-orange-600 px-2.5 py-0.5 text-sm font-medium leading-5 text-white shadow-sm "
                        >
                          Forgot to clock out{" "}
                          {/* <span className="ml-2 text-orange-400 font-bold">
                            {forgotToClockOutAmount.length} times
                          </span> */}
                          {/* <EyeIcon className="w-5 h-5 ml-2" /> */}
                        </p>
                      ) : null}
                      {isAdmin && (
                        <>
                          <p className="font-normal mt-2">
                            Total hours this week:{" "}
                            {moment
                              .duration(
                                entry[key].shifts?.reduce((a, b) => {
                                  return a + b.totalShiftTime;
                                }, 0)
                              )
                              .asHours()}
                          </p>
                          <p className="font-normal">
                            Total break this week:{" "}
                            {moment
                              .duration(
                                entry[key].shifts?.reduce((a, b) => {
                                  return a + b.totalPauseTime;
                                }, 0)
                              )
                              .asHours()}
                          </p>
                        </>
                      )}
                      {isAdmin && (
                        <div style={{ height: "400px" }}>
                          <BarChart
                            hours={entry[key].week.worked}
                            breaks={entry[key].week.breaks}
                          />
                        </div>
                      )}
                      {/* {!isAdmin ? s : null} */}
                    </div>
                  );
                })}
              </ul>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export function ClockOut({ open, setOpen, data }) {
  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-6/12">
                {JSON.stringify(data)}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function Details({ open, setOpen, data }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 w-6/12">
                {data}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function TasksManager({ week, year }) {
  const ww = useSession();
  const c = useInjectToken(client, ww);
  const [open, setOpen] = useState(false);
  const ns = useNotifyState();
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [copyWeek, setCopyWeek] = useState(week);
  const [comments, setComments] = useState({});

  const getcomments = async (w) => {
    (await c).get(`comments/${w || week}`).then(({ data }) => {
      setComments(data);
    });
  };

  const fetchall = async (w) => {
    const searchParams = new URLSearchParams({ year: year });
    (await c).get(`tasks/${w || week}?${searchParams}`).then(({ data }) => {
      setTasks(data);
    });
  };

  useEffect(() => {
    fetchall(week);
  }, [week, year]);

  const deleteTask = async (id) => {
    (await c)
      .post(`tasks/${id}/delete`)
      .then(() => {
        fetchall();
        ns.setNotification({
          title: `Action successful`,
          sub: "",
          Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
        });
      })
      .catch(() => {
        ns.setNotification({
          title: `Something went wrong`,
          sub: "Try again or refresh the page and try again",
          Icon: () => (
            <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
      });
  };

  const duplicateTasksFromWeek = async (w, to) => {
    (await c)
      .post(`tasks/${w}/duplicate-to/${to}`)
      .then(() => {
        fetchall();
        setDuplicateOpen(false);
      })
      .catch(() => {
        ns.setNotification({
          title: `Something went wrong`,
          sub: "Try again or refresh the page and try again",
          Icon: () => (
            <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
      });
    setDuplicateOpen(false);
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
  };

  // const addTask = async () => {
  //   (await c).post(`tasks`, { description: "test", week: week }).then(() => {
  //     fetchall();
  //   });
  // };

  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [comment, setComment] = useState();

  return (
    <>
      <CommentModal comment={comment} setComment={setComment} />
      {/* <DuplicateTasksModel
        duplicate={duplicateTasksFromWeek}
        open={duplicateOpen}
        setOpen={setDuplicateOpen}
      /> */}
      <CreateModal
        open={open}
        year={year}
        selectedTask={selectedTask}
        week={week}
        setOpen={() => {
          setOpen(false);
          fetchall();
        }}
      />
      <div className="mt-10 mb-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-gray-900 py-10 text-white">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-white">
              Tasks
            </h1>
            <p className="mt-2 text-sm text-white">
              A list of tasks to be completed for the week.
            </p>
            {!tasks.tasks?.length ? (
              <p className="mt-10 text-sm text-white font-bold">
                No tasks have been created yet for this week.
              </p>
            ) : null}
          </div>

          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
          <div className="mt-4 ml-3 sm:mt-0 sm:flex-none">
            {/* <button
              type="button"
              onClick={() => {
                setDuplicateOpen(true);
              }}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              duplicate tasks
            </button> */}
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  setOpen(true);
                  setSelectedTask(null);
                }}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add task
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {!tasks.tasks?.length ? null : (
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                      >
                        Task
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Assigned to
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Days
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Completed by
                      </th>
                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                      >
                        Comments
                      </th> */}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {tasks.tasks?.map((task) => (
                      <tr key={task._id}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="">
                              <div className="font-medium text-white">
                                Description
                              </div>
                              <div className="mt-1 text-gray-500">
                                {task.description}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {task.assignedUser.map((a) => (
                            <>
                              <span className="mb-1 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {a.name}
                              </span>
                              <br />
                            </>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {task.days.map((a) => (
                            <>
                              <span className="mb-1 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {moment(a).format("dddd")}
                              </span>
                              <br />
                            </>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {task.completedByAssigneeUser.map((a) => (
                            <>
                              <span className="mb-1 inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-600/20">
                                {moment(a.day).format("dddd")} - {a.name}
                              </span>
                              <br />
                            </>
                          ))}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          {task.assignedComments.map((a) => {
                            return (
                              <>
                                <span
                                  onClick={() => {
                                    setComment({
                                      name: a.name,
                                      day: moment(a.day).format("dddd"),
                                      comment: a.comment,
                                    });
                                  }}
                                  className=" inline-flex cursor-pointer items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20"
                                >
                                  {moment(a.day).format("dddd")} - {a.name}
                                </span>
                                <br />
                              </>
                            );
                          })}
                        </td> */}
                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <button
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={() => {
                              setOpen(true);
                              setSelectedTask(task);
                            }}
                          >
                            Edit<span className="sr-only"></span>
                          </button>
                          <button
                            onClick={() => deleteTask(task._id)}
                            className="text-red-600 ml-5 hover:text-indigo-900"
                          >
                            Delete<span className="sr-only"></span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {tasks.additionalComments?.length ? (
          <>
            <div className="sm:flex-auto pt-14">
              <h1 className="text-base font-semibold leading-6 text-white">
                Cromments
              </h1>
              <p className="mt-2 text-sm text-gray-400">
                Comments left by employees.
              </p>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  {!tasks.additionalComments?.length ? null : (
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className=" py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            day
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                          >
                            Comment
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-700">
                        {tasks.additionalComments?.map((task) => (
                          <tr key={task._id}>
                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                              <div className="flex items-center">
                                <div className="">
                                  <div className="mt-1 text-gray-500">
                                    {task.name}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <>
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {moment(task.day).format("dddd")}{" "}
                                  {moment(task.day).format("dddd") ===
                                  moment().format("dddd")
                                    ? "(Today)"
                                    : ""}
                                </span>
                                <br />
                              </>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <>
                                <span
                                  onClick={() => {
                                    setComment({
                                      name: task.name,
                                      day: moment(task.day).format("dddd"),
                                      comment: task.comment,
                                    });
                                  }}
                                  className=" inline-flex cursor-pointer items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20"
                                >
                                  View comment
                                </span>
                                <br />
                              </>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export function CommentModal({ comment, setComment }) {
  return (
    <Transition.Root show={!!comment} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setComment()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-white">
                      Comment by {comment?.name}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-white">
                      {comment?.name} Left a comment on {comment?.day}.
                    </p>

                    <div className="col-span-full mt-3 text-white">
                      <p>
                        "{comment?.comment}" - {comment?.name}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    onClick={() => setComment()}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function DuplicateTasksModel({ duplicate, open, setOpen }) {
  const [fromWeek, setFromWeek] = useState(moment().week());
  const [toWeek, setToWeek] = useState(moment().week() + 1);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-white bg-slate-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-white">
                      Duplicate tasks
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-200">
                      You can duplicate tasks here from one week to another.
                    </p>

                    <div className="col-span-full mt-3">
                      <div className="mt-2">
                        <p className="text-xs flex mt-3 mb-2">
                          From{" "}
                          <ArrowRightOnRectangleIcon className="w-5 h-5 text-indigo-500" />
                        </p>
                        <select
                          id="location"
                          name="location"
                          className="block w-full bg-slate-900 rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue={moment().week()}
                          value={fromWeek}
                          onChange={(e) => {
                            setFromWeek(e.target.value);
                          }}
                        >
                          {Array.from(Array(52).keys()).map((i) => {
                            return (
                              <option key={i} value={i}>
                                Week {i}
                              </option>
                            );
                          })}
                        </select>
                        <p className="text-xs flex mt-5 mb-2">
                          To{" "}
                          <ArrowLeftOnRectangleIcon className="w-5 h-5 text-indigo-500" />
                        </p>
                        <select
                          id="location"
                          name="location"
                          className="block w-full bg-slate-900 rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue={moment().week() + 1}
                          toWeek={toWeek}
                          onChange={(e) => {
                            setToWeek(e.target.value);
                          }}
                        >
                          {Array.from(Array(52).keys()).map((i) => {
                            return (
                              <option key={i} value={i}>
                                Week {i}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => duplicate(fromWeek, toWeek)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Duplicate
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function CreateModal({ open, setOpen, week, selectedTask, year }) {
  const ww = useSession();
  const c = useInjectToken(client, ww);
  const [users, setUsers] = useState([]);
  const [description, setDescription] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [days, setDays] = useState([]);

  const ns = useNotifyState();

  const fetchall = async (w) => {
    (await c).get(`users`).then(({ data }) => {
      setUsers(data);
    });
  };

  useEffect(() => {
    fetchall();
  }, []);

  const create = async () => {
    const assigned = users.filter((u) => {
      return document.getElementById(`person-${u.id}`).checked;
    });

    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ].filter((u) => {
      return document.getElementById(`person-${u}`).checked;
    });

    const description = document.getElementById(`about`).value;

    await (
      await c
    )
      .post(`tasks/create`, {
        description: description,
        assigned: assigned.map((a) => a.id),
        week: week,
        year: year,
        days: days.map((d) => {
          if (d === "Sunday") {
            return moment()
              .week(String(week))
              .year(String(year))
              .day(d)
              .add(1, "week")
              .startOf("day")
              .toISOString();
          }
          return moment()
            .week(String(week))
            .year(String(year))
            .day(d)
            .startOf("day")
            .toISOString();
        }),
      })
      .catch((e) => {
        ns.setNotification({
          title: `Something went wrong`,
          sub: "Try again or refresh the page and try again",
          Icon: () => (
            <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
        setOpen(false);
        return;
      });
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
    setOpen(false);
  };

  const update = async () => {
    await (
      await c
    )
      .post(`tasks/update`, {
        _id: selectedTaskToUpdate._id,
        description: selectedTaskToUpdate.description,
        assigned: selectedTaskToUpdate.assigned,
        week: selectedTaskToUpdate.week,
        year: selectedTaskToUpdate.year,
        days: selectedTaskToUpdate.days,
      })
      .catch((e) => {
        ns.setNotification({
          title: `Something went wrong`,
          sub: "Try again or refresh the page and try again",
          Icon: () => (
            <ExclamationCircleIcon className="text-orange-400 h-6 w-6" />
          ),
        });
      });
    ns.setNotification({
      title: `Action successful`,
      sub: "",
      Icon: () => <CheckBadgeIcon className="text-green-400 h-6 w-6" />,
    });
    setOpen(false);
  };

  const [selectedTaskToUpdate, setSelectedTaskToUpdate] =
    useState(selectedTask);

  useEffect(() => {
    setSelectedTaskToUpdate(selectedTask);
  }, [selectedTask]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-white">
                      New task
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-300">
                      Create a new task here. You can add a description and add
                      people. You can always change this later.
                    </p>

                    <div className="col-span-full mt-3">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium leading-6 text-white"
                      >
                        Task description
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="about"
                          name="about"
                          rows={1}
                          className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-gray-900"
                          onChange={(e) => {
                            if (!selectedTask) return;
                            setSelectedTaskToUpdate({
                              ...selectedTaskToUpdate,
                              description: e.target.value,
                            });
                          }}
                          defaultValue={
                            selectedTask ? selectedTask.description : ""
                          }
                        />
                      </div>
                      <p className="mt-3 text-sm leading-6 text-gray-300">
                        Write in 1 one sentence what the task is about.
                      </p>
                    </div>
                    <div className="flex mt-10 justify-evenly">
                      <div className="col-span-full mt-3 mr-10">
                        <fieldset>
                          <legend className="text-base font-semibold leading-6 text-white">
                            Assigned
                          </legend>
                          <div className="mt-4 divide-y divide-gray-700 border-b border-t border-gray-200">
                            {users.map((person, personIdx) => (
                              <div
                                key={personIdx}
                                className="relative flex items-start py-2"
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`person-${person.id}`}
                                    className="select-none font-medium text-white"
                                  >
                                    {person.name}
                                  </label>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                  <input
                                    checked={
                                      selectedTaskToUpdate
                                        ? selectedTaskToUpdate.assigned.some(
                                            (a) => person.id === a
                                          )
                                        : null
                                    }
                                    onChange={(e) => {
                                      // if checked, add to array, if not, remove from array
                                      setSelectedTaskToUpdate({
                                        ...selectedTaskToUpdate,
                                        assigned: e.target.checked
                                          ? [
                                              ...selectedTaskToUpdate.assigned,
                                              person.id,
                                            ]
                                          : selectedTaskToUpdate.assigned.filter(
                                              (a) => a !== person.id
                                            ),
                                      });
                                    }}
                                    id={`person-${person.id}`}
                                    name={`person-${person.id}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-span-full mt-3">
                        <fieldset>
                          <legend className="text-base font-semibold leading-6 text-white">
                            Days
                          </legend>
                          <div className="mt-4 divide-y divide-gray-700 border-b border-t border-gray-200">
                            {[
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday",
                            ].map((day, personIdx) => (
                              <div
                                key={personIdx}
                                className="relative flex items-start py-2"
                              >
                                <div className="min-w-0 flex-1 text-sm leading-6">
                                  <label
                                    htmlFor={`person-${day}`}
                                    className="select-none font-medium text-white"
                                  >
                                    {day}
                                  </label>
                                </div>
                                <div className="ml-3 flex h-6 items-center">
                                  <input
                                    checked={
                                      selectedTaskToUpdate
                                        ? selectedTaskToUpdate.days.some(
                                            (a) =>
                                              moment(a).format("dddd") === day
                                          )
                                        : null
                                    }
                                    onChange={(e) => {
                                      // if checked, add to array, if not, remove from array
                                      setSelectedTaskToUpdate({
                                        ...selectedTaskToUpdate,
                                        days: e.target.checked
                                          ? [
                                              ...selectedTaskToUpdate.days,
                                              moment()
                                                .week(String(week))
                                                .day(day)
                                                .startOf("day")
                                                .toISOString(),
                                            ]
                                          : selectedTaskToUpdate.days.filter(
                                              (a) =>
                                                moment(a).format("dddd") !== day
                                            ),
                                      });
                                    }}
                                    id={`person-${day}`}
                                    name={`person-${day}`}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={selectedTask ? update : create}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {selectedTask ? "Update" : "Create"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const people = [
  { id: 1, name: "Annette Black" },
  { id: 2, name: "Cody Fisher" },
  { id: 3, name: "Courtney Henry" },
  { id: 4, name: "Kathryn Murphy" },
  { id: 5, name: "Theresa Webb" },
];

export function NormalTaskView({
  week,
  hasCurrentlyActiveOne,
  tasks,
  setTasks,
}) {
  const u = useUser();
  const ww = useSession();
  const c = useInjectToken(client, ww);

  const complete = async (_id, date) => {
    (await c).post(`tasks/${_id}/${date}/complete/${moment().toISOString()}`);
  };

  const uncomplete = async (_id, date) => {
    (await c).post(`tasks/${_id}/${date}/uncomplete`);
  };

  return (
    <div className="mt-10 mb-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-gray-100 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-white">
            Tasks
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of tasks to be completed for the week.
          </p>
          {!hasCurrentlyActiveOne && (
            <div
              className="flex items-center px-3  py-3 bg-red-100 border-red-200 border-2 mt-6 rounded-md justify-between"
              // style={{ borderWidth: "1px" }}
            >
              <div className="flex items-center">
                <ExclamationTriangleIcon className="h-6 w-6 mr-3 text-orange-600" />
                <p className="text-gray-700">
                  You are currently not checked in. Check in to begin doing
                  tasks.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className=" -my-2 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {Object.keys(tasks).map((task) => {
              return (
                <fieldset className="mt-5">
                  <legend className="text-base font-semibold leading-6 text-white">
                    {task}{" "}
                    {task === moment().format("dddd") &&
                    week ===
                      (moment().day() === 0
                        ? moment().week() - 1
                        : moment().week())
                      ? "(Today)"
                      : null}
                  </legend>
                  <div className="mt-4 divide-y divide-gray-700 border-b border-t border-gray-200">
                    {tasks[task].map((tt, personIdx) => {
                      console.log({ tt });
                      return (
                        <div
                          key={personIdx}
                          className="relative flex items-start py-4"
                        >
                          <div className="min-w-0 flex-1 text-sm leading-6">
                            <label
                              htmlFor={`person-${personIdx}`}
                              className="select-none font-medium text-white"
                            >
                              {tt.description}
                            </label>
                          </div>
                          <div className="ml-3 flex h-6 items-center">
                            <input
                              disabled={
                                task !== moment().format("dddd") ||
                                week !==
                                  (moment().day() === 0
                                    ? moment().week() - 1
                                    : moment().week()) ||
                                !hasCurrentlyActiveOne
                              }
                              checked={tt.completedByAssignee.some((c) => {
                                return (
                                  c.id === u.user.id &&
                                  task ===
                                    dayjs(c.day)
                                      .tz("Europe/Amsterdam")
                                      .format("dddd")
                                );
                              })}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  complete(
                                    tt._id,
                                    moment()
                                      .week(
                                        moment()
                                          .weekday(weekdayMapping[task])
                                          .day() === 0
                                          ? week + 1
                                          : week
                                      )
                                      .weekday(weekdayMapping[task])
                                      .startOf("day")
                                      .toISOString()
                                  );
                                } else {
                                  uncomplete(
                                    tt._id,
                                    moment()
                                      .week(
                                        moment()
                                          .weekday(weekdayMapping[task])
                                          .day() === 0
                                          ? week + 1
                                          : week
                                      )
                                      .weekday(weekdayMapping[task])
                                      .startOf("day")
                                      .toISOString()
                                  );
                                }
                                setTasks({
                                  ...tasks,
                                  [task]: tasks[task].map((t) => {
                                    if (t._id === tt._id) {
                                      return {
                                        ...t,
                                        completedByAssignee: e.target.checked
                                          ? [
                                              ...t.completedByAssignee,
                                              {
                                                id: u.user.id,
                                                day: moment()
                                                  .week(
                                                    moment()
                                                      .weekday(
                                                        weekdayMapping[task]
                                                      )
                                                      .day() === 0
                                                      ? week + 1
                                                      : week
                                                  )
                                                  .weekday(weekdayMapping[task])
                                                  .startOf("day")
                                                  .toISOString(),
                                              },
                                            ]
                                          : t.completedByAssignee.filter(
                                              (c) =>
                                                c.id !== u.user.id &&
                                                task ===
                                                  dayjs(c.day)
                                                    .tz("Europe/Amsterdam")
                                                    .format("dddd")
                                            ),
                                      };
                                    }
                                    return t;
                                  }),
                                });
                              }}
                              id={`person-${tt._id}`}
                              name={`person-${tt._id}`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </fieldset>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
